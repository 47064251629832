import React from "react"
import "./styles.css"

const Macros = ({ data = [], changeField, hiddenMacros = [] }) => {
  const activeMacros =
    data.filter(item => !hiddenMacros.includes(item.value)) || []
  return (
    <div className="BadgeWrapper">
      {activeMacros.map(item => (
        <div
          className="Badge"
          onClick={() => changeField(item.value)}
          key={`${item.value}_${item.name}`}
        >
          {item.name}
        </div>
      ))}
    </div>
  )
}

export default Macros

import React from "react"
import * as Sentry from "@sentry/browser"
import { ConfigProvider } from "antd"
import { ru_RU as ruLocale } from "antd/lib/locale/ru_RU"
import BaseLayout from "./Container/BaseLayout"
import "./App.less"

Sentry.init({
  dsn: "https://a830957d4e1fe634924cd3e3c129d99c@sentry.offline.shelopes.com/6",
})
require("dotenv").config()

const App = () => (
  <ConfigProvider locale={ruLocale}>
    <BaseLayout />
  </ConfigProvider>
)

export default App

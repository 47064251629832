import React from "react"
import moment from "moment"
import "./styles.css"

const EditInfo = ({
  editInfo: { desiredDate, hallComment, authorContact },
}) => (
  <div className="EditInfoWrapper">
    {!!desiredDate && (
      <div>
        <div className="EditInfoTitle">Желаемая дата отправки</div>
        <div className="EditInfoValue">
          {moment.utc(desiredDate).format(`DD.MM.YYYY HH:mm`)}
        </div>
      </div>
    )}
    {!!hallComment && (
      <div className="EditInfoHallComment">
        <div className="EditInfoTitle">Комментарий клуба</div>
        <div className="EditInfoValue">{hallComment}</div>
      </div>
    )}
    {!!authorContact && (
      <div>
        <div className="EditInfoTitle">Контактное лицо</div>
        <div className="EditInfoValue">{authorContact}</div>
      </div>
    )}
  </div>
)

export default EditInfo

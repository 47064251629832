import React, { useEffect, useState } from "react"
import axios from "axios"
import { useParams, useHistory } from "react-router-dom"
import { ERROR_PAGE, SERVER_ERROR_PAGE } from "Container/BaseLayout/constants"

const InfobipGetCallback = ({ ultimateLogout }) => {
  const history = useHistory()
  // eslint-disable-next-line camelcase
  const { request_id, package_id } = useParams()
  const [infoData, setInfoData] = useState({ data: `` })
  useEffect(() => {
    const token = localStorage.getItem(`token`)
    axios({
      method: `post`,
      // eslint-disable-next-line camelcase
      url: `${process.env.REACT_APP_AUTH_URL}/infobip/getcallback/${request_id}/${package_id}`,
      headers: {
        "Content-Type": `application/json`,
        token,
      },
    })
      .then(({ data }) => {
        setInfoData({ ...infoData, data: JSON.stringify(data) })
      })
      .catch(error => {
        if (history && error.response) {
          if (error.response.status === 404) {
            localStorage.setItem(`404`, `show`)
            history.push(ERROR_PAGE)
          }
          if (error.response.status === 403) {
            ultimateLogout()
          }
          if (error.response.status === 500) {
            localStorage.setItem(`500`, `show`)
            history.push(SERVER_ERROR_PAGE)
          }
          throw error
        }
      })
  }, [])

  return <div className="InfobipGetCallbackWrapper">{infoData.data}</div>
}

export default InfobipGetCallback

import React, { useEffect, useState } from "react"
import { Select, Radio, Spin } from "antd"
import RadioComponent from "Components/Radio"
import SelectComponent from "Components/Select"
import DatePickerComponent, {
  RangePickerComponent,
} from "Components/DatePicker"
import {
  CalendarOutlined,
  CaretLeftOutlined,
  LoadingOutlined,
} from "@ant-design/icons"
import SmsCounter from "sms-counter"
import CheckBoxComponent from "Components/CheckBox"
import { useHistory, useParams, useLocation } from "react-router-dom"
import FromToInput from "Components/FromToInput"
import Card from "Components/Card"
import moment from "moment"
import findIndex from "lodash/findIndex"
import isArray from "lodash/isArray"
import get from "lodash/get"
import uuid from "uuid"
import uniq from "lodash/uniq"
import InputComponent, { InputArea } from "Components/Input"
import { useDictionary } from "api/hooks"
import FiltersList from "./FiltersList"
import RightPanel from "./RightPanel"
import Macros from "./Macros"
import Games from "./Games"
import useForm from "./useForm"
import EditInfo from "./EditInfo"
import { useCheckClubNumbers } from "./helper"
import "./styles.css"
import { hasStopWords } from "../helper"

const { Option } = Select

const antIcon = <LoadingOutlined style={{ fontSize: `12px` }} spin />

const MONTH = `Месяц`

export const periodOptionsRu = [
  { value: 1, label: `1 мес.` },
  { value: 6, label: `2 мес.` },
  { value: 2, label: `3 мес.` },
  { value: 3, label: `6 мес.` },
  { value: 4, label: `Год` },
  { value: 5, label: `За весь период` },
  { value: 0, label: <CalendarOutlined /> },
]

export const periodOptionsKz = [
  { value: 1, label: `14 дней` },
  { value: 2, label: `1 мес.` },
  { value: 3, label: `3 мес.` },
  { value: 4, label: `6 мес.` },
  { value: 5, label: `За весь период` },
  { value: 0, label: <CalendarOutlined /> },
]

const RequestForm = ({ ultimateLogout }) => {
  const history = useHistory()
  const back = () => history.push(`/request`)
  const { id } = useParams()
  const { pathname = `` } = useLocation()
  const isCopy = pathname && pathname.search(`copy`) > 0
  const isNew = id === `new`
  const isKz = localStorage.getItem(`local`) === `kz`
  const [subjectPlaceholder, setSubjectPlaceholder] = useState(``)
  const [showNumbers, toggleNumbers] = useState(false)
  const [template, setTemplate] = useState({})
  const [showPlate, setPlateShow] = useState({
    templates: true,
    request: false,
    restrictions: false,
    audience: false,
  })

  const togglePlate = plate =>
    setPlateShow({ ...showPlate, [plate]: !showPlate[plate] })

  // main hook for working with form
  const {
    data,
    editInfo,
    validate,
    setValidate,
    notValid,
    resetFilter,
    changeFilter,
    changeSecondLevelFilter,
    changeSms,
    getSmsFieldValue,
    getFilterFieldValue,
    getSecondLevelFilter,
    // sport
    changeSportField,
    getSportFieldValue,
    // game
    changeGameValue,
    formGames,
    // halls
    hallsData,
    changeHallsData,
    // templates
    applyFilter,
    isTemplateActive,
  } = useForm({
    id,
    history,
    toggleNumbers,
    setTemplate,
    ultimateLogout,
    isCopy,
  })
  // clear template
  useEffect(() => {
    if (!isTemplateActive) {
      setTemplate({})
    }
  }, [isTemplateActive])
  // hook for dictionary
  const {
    data: {
      games,
      gateway,
      subject_type: subjectTypeData,
      guest_count: guestCount,
      macroses,
    },
    data: dictionaryData,
    pending: dictionaryPending,
  } = useDictionary(id, history, ultimateLogout)
  const hallsField = hallsData.data

  // Confines values, for disabled fields
  const disabledSportSumBet = !!getSportFieldValue(`percentage_bets`)
  const confinesAll = !getFilterFieldValue(`confines`)
  const confinesNumbers = getFilterFieldValue(`confines`) === 1
  const confinesHalls = getFilterFieldValue(`confines`) === 2
  const confinesExcludedHalls = getFilterFieldValue(`confines`) === 3
  const confinesPhone = !getFilterFieldValue(`type_filter_bets`)

  // hook for dictionary
  const {
    pending: clubsPending,
    check: clubsCheck,
    clubs: clubsNumbers,
    allAddresses = {},
    errors: clubsErrors,
  } = useCheckClubNumbers({
    clubs: hallsField,
    includeClosed: getFilterFieldValue(`include_closed_clubs`),
    ultimateLogout,
    history,
    allowCheck: !confinesExcludedHalls,
  })
  const currentSubject =
    subjectTypeData[
      findIndex(subjectTypeData, item => {
        return item.id === getSmsFieldValue(`subject_type`)
      })
    ]
  const subjectData =
    getSmsFieldValue(`subject_type`) && !dictionaryPending && currentSubject
      ? currentSubject.subjects
      : []

  const disabledBetDatePicker =
    getFilterFieldValue(`confines_period`).period === 5 &&
    !!getFilterFieldValue(`confines_period`).type
  const disabledFutureDate = current =>
    current && current > moment().endOf("day")
  const disabledBetDate = current => {
    const visitClubs = !!getFilterFieldValue(`confines_period`).type
    const visitFrom = getFilterFieldValue(`confines_period`).from
    const visitTo = getFilterFieldValue(`confines_period`).to
    const visitPeriod = getFilterFieldValue(`confines_period`).period
    const currentData = current.valueOf()
    if (current && !visitPeriod && visitFrom && visitTo) {
      const from = moment(visitFrom).startOf(`day`)
      const to = moment(visitTo).endOf(`day`)
      return visitClubs
        ? currentData > from && currentData < to
        : currentData < from || currentData > to
    }
    if (visitPeriod) {
      const today = moment.utc().valueOf()
      let periodValue
      switch (visitPeriod) {
        case 1:
          periodValue = moment
            .utc(today)
            .subtract(`month`, 1)
            .valueOf()
          break
        case 2:
          periodValue = moment
            .utc(today)
            .subtract(`month`, 3)
            .valueOf()
          break
        case 3:
          periodValue = moment
            .utc(today)
            .subtract(`month`, 6)
            .valueOf()
          break
        case 4:
          periodValue = moment
            .utc(today)
            .subtract(`year`, 1)
            .valueOf()
          break
        default:
          periodValue = null
      }
      if (periodValue) {
        return visitClubs
          ? currentData > periodValue
          : currentData < periodValue || currentData > today
      }
      return visitClubs ? true : currentData > today
    }
    return false
  }

  const smsCount = SmsCounter.count(`${getSmsFieldValue("text")}` || ``)

  const [needValidateStopWords, setNeedValidateStopWords] = useState(true)
  const isValidStopWordsInitState = isKz ? true : !!isNew
  const [isValidStopWords, setIsValidStopWords] = useState(
    isValidStopWordsInitState,
  )

  let waitCheckStopWords

  /**
   * Проверка на стоп-слова
   */
  useEffect(() => {
    if (
      !isKz &&
      data.sms.text &&
      dictionaryData.stop_words &&
      dictionaryData.stop_words.length &&
      needValidateStopWords
    ) {
      waitCheckStopWords = setTimeout(() => {
        if (hasStopWords(data.sms.text, dictionaryData.stop_words)) {
          setIsValidStopWords(false)
          setValidate({
            filter: { sms: { text: "Недопустимая формулировка" } },
          })
        } else {
          setIsValidStopWords(true)
        }
        setNeedValidateStopWords(false)
      }, 1000)
    }
  }, [data.sms.text, needValidateStopWords, dictionaryData.stop_words])

  return (
    <div className="FormWrapper">
      <div className="FormContentWrapper">
        <div className="BreadCrumbsWrapper">
          Заявки на рассылки <span style={{ fontSize: `10px` }}>»</span>{" "}
          {isNew || isCopy ? `Создать ` : `Редактировать `}
          СМС-рассылку
        </div>
        <div className="FormTitle">
          <div className="BackIcon">
            <CaretLeftOutlined
              style={{ fontSize: "24px", color: "#92929d", cursor: "pointer" }}
              onClick={back}
            />
          </div>
          <div>
            {isNew || isCopy ? `Создать` : `Редактировать`} СМС-рассылку
          </div>
        </div>
        <div className="FormPlate">
          <div
            className="PlateTitle"
            onClick={() => togglePlate(`templates`)}
            style={{ paddingBottom: showPlate.templates ? `22px` : `` }}
          >
            Шаблоны
          </div>
          <div style={{ display: showPlate.templates ? `` : `none` }}>
            <FiltersList
              applyFilter={applyFilter}
              ultimateLogout={ultimateLogout}
            />
          </div>
        </div>
        <div className="FormPlate">
          <div
            className="PlateTitle"
            onClick={() => togglePlate(`restrictions`)}
            style={{ paddingBottom: showPlate.restrictions ? `22px` : `` }}
          >
            Ограничения
          </div>
          <div
            className="PlateContent"
            style={{ display: showPlate.restrictions ? `` : `none` }}
          >
            <div className="PlateContentColumn">
              {isKz && (
                <div>
                  <div className="FormElementTitle">ПЛАТФОРМА</div>
                  <CheckBoxComponent
                    label="УКМ"
                    // disabled={getFilterFieldValue(`platform`) === 1}
                    checked={getFilterFieldValue(`platform_ukm`)}
                    onChange={({ target: { checked } }) => {
                      changeFilter(`platform_ukm`, checked)
                    }}
                  />
                  <CheckBoxComponent
                    label="Клубы"
                    // disabled={getFilterFieldValue(`platform`) === 1}
                    checked={getFilterFieldValue(`platform_clubs`)}
                    onChange={({ target: { checked } }) => {
                      changeFilter(`platform_clubs`, checked)
                    }}
                  />
                </div>
              )}
              {!isKz && (
                <div>
                  <div className="FormElementTitle">ОПЛАТА ЗА СЧЕТ</div>
                  <Radio.Group
                    name="payment"
                    value={getFilterFieldValue(`payment`)}
                    onChange={({ target: { value } }) =>
                      confinesAll
                        ? changeFilter([`payment`, `confines`], [value, null])
                        : changeFilter(`payment`, value)
                    }
                  >
                    <RadioComponent value={0}>Компании</RadioComponent>
                    <RadioComponent disabled={confinesAll} value={1}>
                      Партнера
                    </RadioComponent>
                  </Radio.Group>
                </div>
              )}
              <div className="FormElementTitle" style={{ paddingTop: `24px` }}>
                ТИП РАССЫЛКИ
              </div>
              <div>
                <Radio.Group
                  name="type_request"
                  value={getFilterFieldValue(`type_request`)}
                  onChange={({ target: { value } }) => {
                    if (value === 0) {
                      const groupValue = isArray(hallsField)
                        ? hallsField
                        : [hallsField]
                      changeFilter(`type_request`, value)
                      changeHallsData({
                        ...hallsData,
                        data: hallsField ? groupValue : null,
                      })
                    }
                    if (value === 1) {
                      let singleValue = isArray(hallsField)
                        ? hallsField[0]
                        : [hallsField]
                      if (isArray(hallsField)) {
                        singleValue = hallsField[0] ? [hallsField[0]] : null
                      }
                      changeFilter(
                        [`type_request`, `type_filter_bets`],
                        [value, 1],
                      )
                      changeHallsData({
                        ...hallsData,
                        data: hallsField ? singleValue : null,
                      })
                    }
                  }}
                >
                  <RadioComponent value={0}>Групповая</RadioComponent>
                  <RadioComponent
                    disabled={confinesAll || confinesNumbers}
                    value={1}
                  >
                    На один клуб
                  </RadioComponent>
                </Radio.Group>
              </div>
              <div className="FormElementTitle" style={{ paddingTop: `24px` }}>
                ВЫБЕРИТЕ ПЕРИОД
              </div>
              <div>
                <Radio.Group
                  name="period_type"
                  value={getFilterFieldValue(`confines_period`).type}
                  onChange={({ target: { value } }) => {
                    if (value === 2) {
                      changeFilter(
                        `confines_period`,
                        {
                          type: value,
                          period: null,
                          from: null,
                          to: null,
                        },
                        true,
                      )
                    } else {
                      changeFilter(
                        `confines_period`,
                        {
                          type: value,
                          period:
                            getFilterFieldValue(`confines_period`).period ===
                            null
                              ? 5
                              : getFilterFieldValue(`confines_period`).period,
                          from: getFilterFieldValue(`confines_period`).from,
                          to: getFilterFieldValue(`confines_period`).to,
                        },
                        true,
                      )
                    }
                  }}
                >
                  <RadioComponent value={0}>Посещали клубы</RadioComponent>
                  <RadioComponent value={1}>Не посещали клубы</RadioComponent>
                  <RadioComponent value={2}>
                    Не учитывать посещения
                  </RadioComponent>
                </Radio.Group>
                <div style={{ paddingTop: `8px` }}>
                  <Radio.Group
                    disabled={getFilterFieldValue(`confines_period`).type === 2}
                    options={isKz ? periodOptionsKz : periodOptionsRu}
                    onChange={({ target: { value } }) => {
                      changeFilter(`confines_period`, {
                        type: getFilterFieldValue(`confines_period`).type,
                        period: value,
                        from: null,
                        to: null,
                      })
                    }}
                    value={getFilterFieldValue(`confines_period`).period}
                    optionType="button"
                    className="PeriodRadio"
                  />
                </div>
                <div style={{ paddingTop: `12px` }}>
                  <RangePickerComponent
                    error={
                      get(
                        validate,
                        `filter.filters.confines_period.from`,
                        false,
                      ) ||
                      get(validate, `filter.filters.confines_period.to`, false)
                    }
                    disabledDate={disabledFutureDate}
                    disabled={
                      !!getFilterFieldValue(`confines_period`).period ||
                      getFilterFieldValue(`confines_period`).type === 2
                    }
                    name="confines_period"
                    value={[
                      getFilterFieldValue(`confines_period`).from,
                      getFilterFieldValue(`confines_period`).to,
                    ]}
                    onChange={value => {
                      changeFilter(`confines_period`, {
                        type: getFilterFieldValue(`confines_period`).type,
                        period: 0,
                        from: value ? value[0].valueOf() : value,
                        to: value ? value[1].valueOf() : value,
                      })
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="PlateContentColumn">
              <div className="FormElementTitle">
                {isKz ? `НОМЕР КЛУБА` : `ОГРАНИЧЕНИЯ`}
              </div>
              {!isKz && (
                <Radio.Group
                  name="confines"
                  value={getFilterFieldValue(`confines`)}
                  onChange={({ target: { value } }) => {
                    if (value === 0 || value === 3) {
                      changeFilter(
                        [
                          `confines`,
                          `type_phones`,
                          `payment`,
                          `type_request`,
                          `type_filter_bets`,
                          `phones`,
                          `bonus_balance`,
                          `money_balance`,
                        ],
                        [
                          value,
                          2,
                          0,
                          0,
                          0,
                          null,
                          { from: null, to: null },
                          { from: null, to: null },
                        ],
                      )
                      changeHallsData({ ...hallsData, data: null })
                    }
                    if (value === 1) {
                      changeFilter(
                        [
                          `confines`,
                          `include_closed_clubs`,
                          `type_phones`,
                          `type_request`,
                          `phones`,
                        ],
                        [value, false, 1, 0, null],
                      )
                      changeHallsData({ ...hallsData, data: null })
                    }
                    if (value === 2) {
                      changeFilter([`confines`, `type_phones`], [value, 0])
                    }
                  }}
                >
                  <RadioComponent value={0}>Вся сеть РФ</RadioComponent>
                  <RadioComponent className="PaddingForStupid" value={3}>
                    Вся сеть, исключая клубы
                  </RadioComponent>
                  <RadioComponent value={1}>По списку номеров</RadioComponent>
                  <RadioComponent value={2}>Номер клуба</RadioComponent>
                </Radio.Group>
              )}
              <div style={{ paddingTop: `8px` }}>
                {!getFilterFieldValue(`type_request`) ? (
                  <SelectComponent
                    name="halls"
                    error={
                      get(validate, `filter.filters.halls`) ||
                      !!clubsNumbers.length
                    }
                    disabled={!(confinesExcludedHalls || confinesHalls)}
                    className="SelectClubsWrapper"
                    mode="tags"
                    style={{ width: `100%` }}
                    value={hallsField || []}
                    onChange={value => {
                      if (
                        !hallsData.data ||
                        hallsData.data.length < value.length
                      ) {
                        const lastEnter = value
                          .pop()
                          .split(`,`)
                          .map(Number)
                        const newValue = uniq([...value, ...lastEnter])
                        changeHallsData({ ...hallsData, data: newValue })
                        if (isKz && isArray(newValue) && newValue.length > 1) {
                          changeSms(`text`, null)
                        }
                      } else {
                        if (isKz && isArray(value) && value.length > 1) {
                          changeSms(`text`, null)
                        }
                        changeHallsData({ ...hallsData, data: value })
                      }
                    }}
                  />
                ) : (
                  <InputComponent
                    name="halls"
                    error={
                      get(validate, `filter.filters.halls`) ||
                      !!clubsNumbers.length
                    }
                    disabled={!(confinesExcludedHalls || confinesHalls)}
                    style={{ width: `100%` }}
                    value={isArray(hallsField) ? hallsField[0] : hallsField}
                    onChange={({ target: { value } }) => {
                      setValidate(undefined)
                      changeHallsData({
                        ...hallsData,
                        data: value ? [value] : null,
                      })
                    }}
                  />
                )}
              </div>
              {!clubsCheck && !clubsErrors && (
                <div className="ClubErrorWrapper">
                  Клуб:{" "}
                  {clubsNumbers.join(`${clubsNumbers.length ? `, ` : ` `}`)}{" "}
                  отключен или не найден
                </div>
              )}
              {!!clubsErrors && (
                <div className="ClubErrorWrapper">{clubsErrors}</div>
              )}
              <div style={{ paddingTop: `16px` }}>
                <CheckBoxComponent
                  label="Учитывать закрытые клубы"
                  disabled={!confinesHalls}
                  checked={getFilterFieldValue(`include_closed_clubs`)}
                  onChange={({ target: { checked } }) => {
                    changeFilter(`include_closed_clubs`, checked)
                  }}
                />
              </div>
              {!!allAddresses &&
                !!Object.keys(allAddresses).length &&
                !!hallsField &&
                !!hallsField.length && (
                  <div className="AllAddresses">
                    <div className="AddressesSpinWrapper">
                      <div className="AllAddressesTitle">Адреса клубов: </div>
                      {!!clubsPending && <Spin indicator={antIcon} />}
                    </div>
                    {Object.entries(allAddresses).map(([hall, addressses]) => {
                      return (
                        <div className="ClubAddressWrapper" key={hall}>
                          <div className="ClubNumber">{`${hall}:`}</div>
                          <div className="ClubDetails">
                            <div className="RealAddress">
                              {addressses.address}
                            </div>
                            <div className="LegalAddress">
                              {addressses.address_stom}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )}
            </div>
            <div className="PlateContentColumn">
              <div className="FormElementTitle">РАЗОСЛАТЬ ПО</div>
              <Radio.Group
                name="type_phones"
                value={getFilterFieldValue(`type_phones`)}
                onChange={({ target: { value } }) => {
                  if (!value) {
                    changeFilter([`type_phones`, `phones`], [value, null])
                  } else {
                    changeFilter(`type_phones`, value)
                  }
                }}
              >
                <RadioComponent
                  disabled={
                    confinesAll || confinesNumbers || confinesExcludedHalls
                  }
                  value={0}
                >
                  Всем номерам клуба
                </RadioComponent>
                <RadioComponent
                  disabled={confinesAll || confinesExcludedHalls}
                  value={1}
                >
                  Только по номерам
                </RadioComponent>
                <RadioComponent disabled={confinesNumbers} value={2}>
                  По всем номерам кроме
                </RadioComponent>
              </Radio.Group>
              <div style={{ paddingTop: `8px` }}>
                <InputArea
                  rows={10}
                  disabled={!getFilterFieldValue(`type_phones`)}
                  error={get(validate, `filter.filters.phones`)}
                  value={getFilterFieldValue(`phones`)}
                  onChange={({ target: { value } }) => {
                    const withoutSpaces = `${value}`
                      .replaceAll(` `, ``)
                      .replaceAll(`\n`, ``)
                    changeFilter(
                      `phones`,
                      withoutSpaces ? withoutSpaces.split(`,`) : null,
                    )
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="FormPlate">
          <div
            className="PlateTitle"
            onClick={() => togglePlate(`request`)}
            style={{ paddingBottom: showPlate.request ? `22px` : `` }}
          >
            Рассылка
          </div>
          <div
            className="PlateContent"
            style={{ display: showPlate.request ? `` : `none` }}
          >
            <div className="PlateContentColumn">
              <div className="FormElementTitle">ТЕМА РАССЫЛКИ</div>
              <div>
                <SelectComponent
                  name="subject_type"
                  error={get(validate, `filter.sms.subject_type`)}
                  disabled={dictionaryPending}
                  loading={dictionaryPending}
                  value={getSmsFieldValue(`subject_type`)}
                  style={{ width: `100%` }}
                  onChange={(value, { subjectPlaceHolder }) => {
                    changeSms([`subject_type`, `subject`], [value, null], true)
                    setSubjectPlaceholder(subjectPlaceHolder)
                  }}
                  placeholder="Выберите тему"
                >
                  {subjectTypeData.map(item => (
                    <Option
                      value={item.id}
                      key={uuid.v4()}
                      subjectPlaceHolder={item.placeholder}
                    >
                      {item.name}
                    </Option>
                  ))}
                </SelectComponent>
              </div>
              <div>
                {getSmsFieldValue(`subject_type`) !== 0 ? (
                  <SelectComponent
                    name="subject"
                    disabled={
                      !getSmsFieldValue(`subject_type`) || dictionaryPending
                    }
                    error={get(validate, `filter.sms.subject`)}
                    value={getSmsFieldValue(`subject`)}
                    style={{ width: `100%`, paddingTop: `16px` }}
                    onChange={value => changeSms(`subject`, value, true)}
                    placeholder={subjectPlaceholder}
                    loading={dictionaryPending}
                  >
                    {getSmsFieldValue(`subject_type`) &&
                      subjectData.map(item => (
                        <Option value={item.id} key={uuid.v4()}>
                          {item.name}
                        </Option>
                      ))}
                  </SelectComponent>
                ) : (
                  <div style={{ paddingTop: `16px` }}>
                    <InputComponent
                      name="subject"
                      error={get(validate, `filter.sms.subject`)}
                      disabled={dictionaryPending}
                      style={{ width: `100%` }}
                      value={getSmsFieldValue(`subject`)}
                      onChange={({ target: { value } }) =>
                        changeSms(`subject`, value)
                      }
                    />
                  </div>
                )}
              </div>
              {!isKz && (
                <div>
                  <div
                    className="FormElementTitle"
                    style={{ paddingTop: `28px` }}
                  >
                    НОМЕР ТИКЕТА
                  </div>
                  <div>
                    <InputComponent
                      error={get(validate, `filter.sms.ticket_id`)}
                      name="ticket_id"
                      value={getSmsFieldValue(`ticket_id`)}
                      onChange={({ target: { value } }) =>
                        changeSms(`ticket_id`, value)
                      }
                    />
                  </div>
                </div>
              )}
              <div className="FormElementTitle" style={{ paddingTop: `28px` }}>
                ДАТА ОТПРАВЛЕНИЯ
              </div>
              <div>
                <DatePickerComponent
                  style={{ width: `100%` }}
                  error={get(validate, `filter.sms.dttm_to_send`)}
                  placeholder="Дата отправления"
                  name="dttm_to_send"
                  value={getSmsFieldValue(`dttm_to_send`)}
                  showTime={{ format: "HH:mm" }}
                  format="DD.MM.YYYY HH:mm"
                  onChange={value =>
                    changeSms(
                      `dttm_to_send`,
                      value ? moment(value).valueOf() : null,
                    )
                  }
                />
              </div>
              <div className="FormElementTitle" style={{ paddingTop: `28px` }}>
                ШЛЮЗ ОТПРАВЛЕНИЯ
              </div>
              <div>
                <SelectComponent
                  name="gateway"
                  error={get(validate, `filter.sms.gateway`)}
                  style={{ width: `100%` }}
                  value={getSmsFieldValue(`gateway`)}
                  onChange={value => changeSms(`gateway`, value)}
                  loading={dictionaryPending}
                  disabled={dictionaryPending}
                >
                  {gateway.map(item => (
                    <Option value={item.id} key={uuid.v4()}>
                      {item.name}
                    </Option>
                  ))}
                </SelectComponent>
              </div>
            </div>
            <div className="PlateContentColumn">
              <div className="FormElementTitle">ТЕКСТ СМС</div>
              <div className="BadgeWrapper">
                <Macros
                  data={macroses}
                  hiddenMacros={
                    isKz && isArray(hallsField) && hallsField.length > 1
                      ? [`%CITY%, %ADDRESS%`]
                      : []
                  }
                  changeField={value => {
                    return changeSms(
                      `text`,
                      (getSmsFieldValue(`text`) || ``) + value,
                    )
                  }}
                />
              </div>
              <div style={{ paddingTop: `4px` }}>
                <InputArea
                  rows={6}
                  error={get(validate, `filter.sms.text`)}
                  value={getSmsFieldValue(`text`)}
                  onChange={({ target: { value } }) => {
                    changeSms(`text`, value ? `${value}` : null)
                    if (!isKz) {
                      setIsValidStopWords(false)
                      clearTimeout(waitCheckStopWords)
                      setNeedValidateStopWords(true)
                    }
                  }}
                />
              </div>
            </div>
            <div className="PlateContentColumn">
              <div className="TextInfoWrapper">
                Количество символов:
                <p>
                  {getSmsFieldValue(`text`)
                    ? getSmsFieldValue(`text`).length
                    : 0}
                </p>
              </div>
              <div className="TextInfoWrapper">
                Всего СМС:
                <p>{smsCount ? smsCount.messages : 0}</p>
              </div>
              <div className="TextInfoWrapper">
                Символов в одном СМС:
                <p>{smsCount ? smsCount.per_message : 160}</p>
              </div>
              {!isNew && !isCopy && <EditInfo editInfo={editInfo} />}
            </div>
          </div>
        </div>
        <div className="FormPlate">
          <div
            className="PlateTitle"
            onClick={() => togglePlate(`audience`)}
            style={{ paddingBottom: showPlate.audience ? `22px` : `` }}
          >
            Аудитория
          </div>
          <div
            style={{
              display: showPlate.audience ? `` : `none`,
              marginBottom: `24px`,
            }}
          >
            <Radio.Group
              name="type_phones"
              value={getFilterFieldValue(`type_filter_bets`)}
              onChange={({ target: { value } }) =>
                changeFilter(
                  [`type_filter_bets`, `bonus_balance`, `money_balance`],
                  [value, { from: null, to: null }, { from: null, to: null }],
                )
              }
            >
              <RadioComponent
                disabled={getFilterFieldValue(`type_request`)}
                value={0}
              >
                Телефону
              </RadioComponent>
              <RadioComponent disabled={confinesAll} value={1}>
                Кошельку
              </RadioComponent>
            </Radio.Group>
            <div style={{ paddingTop: `20px` }}>
              <Card title="Игры">
                <Games
                  changeGameValue={changeGameValue}
                  validate={validate}
                  disabledBetDatePicker={disabledBetDatePicker}
                  disabledBetDate={disabledBetDate}
                  formGames={formGames}
                  options={games}
                />
              </Card>
            </div>
            {!isKz && (
              <div style={{ paddingTop: `20px` }}>
                <Card title="Спорт">
                  <div>
                    <div className="PlateContent">
                      <div className="PlateContentColumn">
                        <div className="FormElementTitle">
                          ДАТА ПОСЛЕДНЕЙ СТАВКИ
                        </div>
                        <div>
                          <DatePickerComponent
                            style={{ width: `100%` }}
                            placeholder="Выберите дату"
                            name="dttm"
                            error={get(
                              validate,
                              `filter.filters.game_type.sport.dttm`,
                            )}
                            disabled={disabledBetDatePicker}
                            disabledDate={disabledBetDate}
                            value={getSportFieldValue(`dttm`)}
                            onChange={value => {
                              changeSportField(
                                `dttm`,
                                value ? value.valueOf() : null,
                              )
                            }}
                          />
                        </div>
                      </div>
                      <div className="PlateContentColumn">
                        <div className="FormElementTitle">
                          ПРОЦЕНТ ОТ СТАВОК БОЛЕЕ
                        </div>
                        <Radio.Group
                          name="sport_percentage_bets"
                          value={getSportFieldValue(`percentage_bets`)}
                          onChange={({ target: { value } }) => {
                            changeSportField(
                              [`percentage_bets`, `sum_from`, `sum_to`],
                              [value, null, null],
                              true,
                            )
                          }}
                        >
                          <RadioComponent value={0}>
                            не учитывать
                          </RadioComponent>
                          <RadioComponent value={1}>10%</RadioComponent>
                          <RadioComponent value={2}>20%</RadioComponent>
                        </Radio.Group>
                      </div>
                      <div
                        className="PlateContentColumn"
                        style={{ textAlign: `center` }}
                      >
                        <div className="FormElementTitle">ИЛИ</div>
                      </div>
                      <div className="PlateContentColumn">
                        <div className="FormElementTitle">СУММА СТАВОК</div>
                        <div style={{ padding: `0 0 12px 0` }}>
                          <FromToInput
                            disabled={disabledSportSumBet}
                            error={
                              get(
                                validate,
                                `filter.filters.game_type.sport.sum_from`,
                              ) ||
                              get(
                                validate,
                                `filter.filters.game_type.sport.sum_to`,
                              )
                            }
                            fromValue={getSportFieldValue(`sum_from`)}
                            changeFrom={value =>
                              changeSportField(`sum_from`, value, true)
                            }
                            toValue={getSportFieldValue(`sum_to`)}
                            changeTo={value =>
                              changeSportField(`sum_to`, value, true)
                            }
                          />
                        </div>
                        <div className="FormElementTitle">ПЕРИОД СТАВКИ</div>
                        <div>
                          <RangePickerComponent
                            error={
                              get(
                                validate,
                                `filter.filters.game_type.sport.dttm_from`,
                              ) ||
                              get(
                                validate,
                                `filter.filters.game_type.sport.dttm_to`,
                              )
                            }
                            disabledDate={disabledBetDate}
                            disabled={
                              getSportFieldValue(`dttm_all`) ||
                              disabledSportSumBet
                            }
                            value={[
                              getSportFieldValue(`dttm_from`),
                              getSportFieldValue(`dttm_to`),
                            ]}
                            ranges={{
                              [MONTH]: [
                                moment.utc().startOf("month"),
                                moment.utc().endOf("month"),
                              ],
                            }}
                            onChange={value => {
                              changeSportField(
                                [`dttm_from`, `dttm_to`],
                                value
                                  ? [value[0].valueOf(), value[1].valueOf()]
                                  : [null, null],
                              )
                            }}
                          />
                        </div>
                        <div style={{ paddingTop: `16px` }}>
                          <CheckBoxComponent
                            label="За весь период"
                            disabled={disabledSportSumBet}
                            checked={getSportFieldValue(`dttm_all`)}
                            onChange={({ target: { checked } }) =>
                              changeSportField(
                                [`dttm_from`, `dttm_to`, `dttm_all`],
                                [null, null, checked],
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="PlateContentColumn">
                        <div className="FormElementTitle">БАЛАНС</div>
                        <Radio.Group
                          name="sport_balance"
                          value={getSportFieldValue(`balance`)}
                          onChange={({ target: { value } }) => {
                            changeSportField(`balance`, value, true)
                          }}
                        >
                          <RadioComponent value={0}>Денежный</RadioComponent>
                          <RadioComponent value={1}>Фрибет</RadioComponent>
                        </Radio.Group>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            )}
            {!isKz && (
              <div style={{ paddingTop: `20px` }}>
                <Card title="Топ гостей">
                  <div>
                    <div className="PlateContent">
                      <div className="PlateContentColumn">
                        <div className="FormElementTitle">УЧЕСТЬ ТОП ПО</div>
                        <Radio.Group
                          name="top"
                          value={getFilterFieldValue(`top`)}
                          onChange={({ target: { value } }) => {
                            changeFilter(`top`, value, true)
                          }}
                        >
                          <RadioComponent value={0}>Win</RadioComponent>
                          <RadioComponent value={1}>
                            Сумма ставок
                          </RadioComponent>
                        </Radio.Group>
                      </div>
                      <div className="PlateContentColumn">
                        <div className="FormElementTitle">
                          СТАВКИ В КАТЕГОРИИ
                        </div>
                        <Radio.Group
                          name="top_bet"
                          value={getFilterFieldValue(`top_bet`)}
                          onChange={({ target: { value } }) => {
                            changeFilter(`top_bet`, value, true)
                          }}
                        >
                          <RadioComponent value={0}>Игры</RadioComponent>
                          <RadioComponent value={1}>Спорт</RadioComponent>
                          <RadioComponent value={2}>
                            Общий оборот
                          </RadioComponent>
                        </Radio.Group>
                      </div>
                      <div className="PlateContentColumn">
                        <div className="FormElementTitle">
                          КОЛИЧЕСТВО ГОСТЕЙ
                        </div>
                        <div>
                          <SelectComponent
                            name="guest_count"
                            allowClear
                            error={get(validate, `filter.filters.guest_count`)}
                            style={{ width: `100%` }}
                            value={getFilterFieldValue(`guest_count`)}
                            onChange={value =>
                              changeFilter(`guest_count`, value, true)
                            }
                          >
                            {guestCount.map(item => (
                              <Option key={uuid.v4()} value={item.id}>
                                {item.name}
                              </Option>
                            ))}
                          </SelectComponent>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            )}
            <div className="PlateContent" style={{ paddingTop: `28px` }}>
              <div className="PlateContentColumn">
                <div className="FormElementTitle">БОНУСНЫЙ БАЛАНС</div>
                <div>
                  <FromToInput
                    name="bonus_balance"
                    error={
                      get(validate, `filter.filters.bonus_balance.from`) ||
                      get(validate, `filter.filters.bonus_balance.to`)
                    }
                    disabled={confinesPhone}
                    fromValue={getSecondLevelFilter(`bonus_balance`, `from`)}
                    changeFrom={value =>
                      changeSecondLevelFilter(
                        `bonus_balance`,
                        `from`,
                        value,
                        true,
                      )
                    }
                    toValue={getSecondLevelFilter(`bonus_balance`, `to`)}
                    changeTo={value =>
                      changeSecondLevelFilter(
                        `bonus_balance`,
                        `to`,
                        value,
                        true,
                      )
                    }
                  />
                </div>
                <div>
                  <div
                    style={{ paddingTop: `12px` }}
                    className="FormElementTitle"
                  >
                    ДЕНЕЖНЫЙ БАЛАНС
                  </div>
                  <FromToInput
                    name="money_balance"
                    error={
                      get(validate, `filter.filters.money_balance.from`) ||
                      get(validate, `filter.filters.money_balance.to`)
                    }
                    disabled={confinesPhone}
                    fromValue={getSecondLevelFilter(`money_balance`, `from`)}
                    changeFrom={value =>
                      changeSecondLevelFilter(
                        `money_balance`,
                        `from`,
                        value,
                        true,
                      )
                    }
                    toValue={getSecondLevelFilter(`money_balance`, `to`)}
                    changeTo={value =>
                      changeSecondLevelFilter(
                        `money_balance`,
                        `to`,
                        value,
                        true,
                      )
                    }
                  />
                </div>
              </div>
              <div className="PlateContentColumn" style={{ width: `100%` }}>
                {!isKz && (
                  <div>
                    <div className="FormElementTitle">WIN КЛУБА</div>
                    <div className="FormElementDoubleWrapper">
                      <FromToInput
                        name="win"
                        inputError={
                          get(validate, `filter.filters.win.sum_from`) ||
                          get(validate, `filter.filters.win.sum_to`)
                        }
                        pickerError={
                          get(validate, `filter.filters.win.dttm_from`) ||
                          get(validate, `filter.filters.win.dttm_to`)
                        }
                        fromValue={getSecondLevelFilter(`win`, `sum_from`)}
                        changeFrom={value =>
                          changeSecondLevelFilter(
                            `win`,
                            `sum_from`,
                            value,
                            true,
                          )
                        }
                        toValue={getSecondLevelFilter(`win`, `sum_to`)}
                        changeTo={value =>
                          changeSecondLevelFilter(`win`, `sum_to`, value, true)
                        }
                        dateProps={{
                          disabled: getSecondLevelFilter(`win`, `dttm_all`),
                          value: [
                            getSecondLevelFilter(`win`, `dttm_from`),
                            getSecondLevelFilter(`win`, `dttm_to`),
                          ],
                          onChange: value => {
                            changeSecondLevelFilter(
                              `win`,
                              [`dttm_from`, `dttm_to`],
                              value
                                ? [value[0].valueOf(), value[1].valueOf()]
                                : [null, null],
                            )
                          },
                        }}
                        withDate
                      />
                      <div style={{ marginTop: `-1px` }} />
                    </div>
                    <div style={{ paddingTop: `16px` }}>
                      <CheckBoxComponent
                        label="За весь период"
                        checked={getSecondLevelFilter(`win`, `dttm_all`)}
                        onChange={({ target: { checked } }) => {
                          changeSecondLevelFilter(
                            `win`,
                            [`dttm_from`, `dttm_to`, `dttm_all`],
                            [null, null, checked],
                          )
                        }}
                      />
                    </div>
                    <div
                      className="FormElementTitle"
                      style={{ paddingTop: `24px` }}
                    >
                      СУММА ДЕПОЗИТА
                    </div>
                    <div className="FormElementDoubleWrapper">
                      <FromToInput
                        name="deposit"
                        inputError={
                          get(validate, `filter.filters.deposit.sum_from`) ||
                          get(validate, `filter.filters.deposit.sum_to`)
                        }
                        pickerError={
                          get(validate, `filter.filters.deposit.dttm_from`) ||
                          get(validate, `filter.filters.deposit.dttm_to`)
                        }
                        fromValue={getSecondLevelFilter(`deposit`, `sum_from`)}
                        changeFrom={value =>
                          changeSecondLevelFilter(
                            `deposit`,
                            `sum_from`,
                            value,
                            true,
                          )
                        }
                        toValue={getSecondLevelFilter(`deposit`, `sum_to`)}
                        changeTo={value =>
                          changeSecondLevelFilter(
                            `deposit`,
                            `sum_to`,
                            value,
                            true,
                          )
                        }
                        dateProps={{
                          disabled: getSecondLevelFilter(`deposit`, `dttm_all`),
                          value: [
                            getSecondLevelFilter(`deposit`, `dttm_from`),
                            getSecondLevelFilter(`deposit`, `dttm_to`),
                          ],
                          onChange: value => {
                            changeSecondLevelFilter(
                              `deposit`,
                              [`dttm_from`, `dttm_to`],
                              value
                                ? [value[0].valueOf(), value[1].valueOf()]
                                : [null, null],
                            )
                          },
                        }}
                        withDate
                      />
                      <div style={{ marginTop: `-1px` }} />
                    </div>
                    <div style={{ paddingTop: `16px` }}>
                      <CheckBoxComponent
                        label="За весь период"
                        checked={getSecondLevelFilter(`deposit`, `dttm_all`)}
                        onChange={({ target: { checked } }) => {
                          changeSecondLevelFilter(
                            `deposit`,
                            [`dttm_from`, `dttm_to`, `dttm_all`],
                            [null, null, checked],
                          )
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="PlateContentColumn">
                {!isKz && (
                  <div>
                    <div className="FormElementTitle">КОЛИЧЕСТВО ВИЗИТОВ</div>
                    <div>
                      <FromToInput
                        name="visits"
                        error={
                          get(validate, `filter.filters.visits.from`) ||
                          get(validate, `filter.filters.visits.to`)
                        }
                        fromValue={getSecondLevelFilter(`visits`, `from`)}
                        changeFrom={value =>
                          changeSecondLevelFilter(`visits`, `from`, value, true)
                        }
                        toValue={getSecondLevelFilter(`visits`, `to`)}
                        changeTo={value =>
                          changeSecondLevelFilter(`visits`, `to`, value, true)
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <RightPanel
          resetFilter={resetFilter}
          data={{ ...data, filters: { ...data.filters, halls: hallsField } }}
          id={id}
          isNew={isNew}
          isCopy={isCopy}
          ultimateLogout={ultimateLogout}
          setValidate={setValidate}
          history={history}
          isValidStopWords={isValidStopWords}
          needValidateStopWords={needValidateStopWords}
          notValid={notValid}
          showNumbers={showNumbers}
          toggleNumbers={toggleNumbers}
          template={template}
          setTemplate={setTemplate}
          dictionaryData={dictionaryData}
        />
      </div>
    </div>
  )
}

export default RequestForm

import React, { useState } from "react"
import InputComponent from "Components/Input"
import ButtonDefault, { ButtonPrimary } from "Components/Button"

import "./styles.css"

const TemplateForm = ({ close, repeat, save, template }) => {
  const [templateName, setTemplateName] = useState(null)
  const isError = template.status === `error`
  return (
    <div>
      {template.status ? (
        <div
          style={{
            display: `flex`,
            flexDirection: `column`,
            alignItems: `center`,
            justifyContent: `center`,
          }}
        >
          <div style={{ paddingBottom: `28px` }}>
            {isError ? `Что-то пошло не так, попробуйте еще раз` : ``}
          </div>
          <ButtonPrimary onClick={isError ? repeat : close}>
            {isError ? `Повторить` : `Хорошо`}
          </ButtonPrimary>
        </div>
      ) : (
        <div>
          <div style={{ paddingBottom: `28px` }}>Добавьте название шаблону</div>
          <div style={{ paddingBottom: `16px` }}>
            <InputComponent
              placeholder="Название"
              value={templateName}
              onChange={({ target: { value } }) => setTemplateName(value)}
            />
          </div>
          <div className="ButtonWrapper">
            <ButtonPrimary onClick={() => save(templateName)}>
              Сохранить
            </ButtonPrimary>
            <div style={{ paddingLeft: `16px` }}>
              <ButtonDefault onClick={close}>Отменить</ButtonDefault>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default TemplateForm

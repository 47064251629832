import { useEffect } from "react"

const GlobalEscapeHandle = ({ onEscapePress }) => {
  const handleEscape = event => {
    if (event.keyCode === 27) {
      onEscapePress(event)
    }
  }
  useEffect(() => {
    document.addEventListener(`keydown`, e => handleEscape(e))
    return document.removeEventListener(`keydown`, e => handleEscape(e))
  })
  return null
}

export default GlobalEscapeHandle

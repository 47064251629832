import betRequest from "api/request"
import omit from "lodash/omit"

const sendRequest = ({
  isNew,
  isCopy,
  id,
  templateId,
  data,
  setModalStatus,
  setSendPending,
  history,
  ultimateLogout,
}) => {
  setSendPending(true)
  const username = localStorage.getItem(`username`)
  // const date = new Date()
  // const timeOffset = date.getTimezoneOffset() * 60000
  const isKz = localStorage.getItem(`local`) === `kz`
  const smsData = {
    ...data.sms,
    // dttm_to_send: data.sms.dttm_to_send
    //   ? data.sms.dttm_to_send - timeOffset
    //   : null,
  }
  const requestBody = {
    filter_id: templateId,
    filter: {
      filters: isKz
        ? omit(
            {
              ...data.filters,
              type_game: { game: data.filters.type_game.game },
            },
            [
              `payment`,
              `win`,
              `deposit`,
              `visits`,
              `top`,
              `top_bet`,
              `guest_count`,
              `ticket_id`,
            ],
          )
        : data.filters,
      sms: omit(smsData, isKz ? `ticket_id` : ``),
    },
    username,
  }
  betRequest({
    method: `post`,
    url: isNew || isCopy ? `request/add` : `request/update`,
    requestBody: isNew || isCopy ? requestBody : { ...requestBody, id },
    history,
    ultimateLogout,
  })
    .then(() => {
      setSendPending(false)
      setModalStatus(`complete`)
    })
    .catch(() => {
      setSendPending(false)
      setModalStatus(`error`)
    })
}

export default sendRequest

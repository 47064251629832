import { useEffect, useState } from "react"
import isArray from "lodash/isArray"
import isEmpty from "lodash/isEmpty"
import betRequest from "api/request"
import { getNewValue } from "./helper"

const rangeData = value => {
  const date = new Date()
  const timeOffset = date.getTimezoneOffset() * 60000
  return value ? value + timeOffset : null
}

const initialPlatformFiltersData = {
  platform_ukm: false,
  platform_clubs: false,
  type_filter_bets: 1,
}

const initialPlatformSmsData = {
  gateway: 2,
}

const useForm = ({
  id,
  isCopy,
  history,
  toggleNumbers,
  setTemplate,
  ultimateLogout,
}) => {
  const isKz = localStorage.getItem(`local`) === `kz`

  const initialData = {
    filters: {
      // Ограничения
      payment: 1,
      type_request: 0,
      confines_period: {
        type: 0,
        from: null,
        to: null,
        period: isKz ? 5 : 1,
      },
      confines: 2,
      include_closed_clubs: false,
      type_phones: 0,
      phones: null,
      // Аудитория
      type_filter_bets: 0,
      type_game: {
        game: [
          {
            games: null,
            dttm: null,
            percentage_bets: 0,
            sum_from: null,
            sum_to: null,
            dttm_from: null,
            dttm_to: null,
            dttm_all: false,
            balance: 0,
          },
        ],
        sport: {
          dttm: null,
          sum_from: null,
          sum_to: null,
          dttm_from: null,
          dttm_to: null,
          dttm_all: false,
          percentage_bets: 0,
          balance: 0,
        },
      },
      top: 0,
      top_bet: 0,
      guest_count: null,
      bonus_balance: {
        from: null,
        to: null,
      },
      money_balance: {
        from: null,
        to: null,
      },
      win: {
        sum_from: null,
        sum_to: null,
        dttm_from: null,
        dttm_to: null,
        dttm_all: false,
      },
      deposit: {
        sum_from: null,
        sum_to: null,
        dttm_from: null,
        dttm_to: null,
        dttm_all: false,
      },
      visits: {
        from: null,
        to: null,
      },
    },
    // Рассылка
    sms: {
      text: null,
      ticket_id: null,
      dttm_to_send: null,
      gateway: 1,
      subject: null,
      subject_type: null,
    },
    filter_id: null,
  }

  const initialDataByLocal = {
    data: isKz
      ? {
          ...initialData,
          filters: { ...initialData.filters, ...initialPlatformFiltersData },
          sms: { ...initialData.sms, ...initialPlatformSmsData },
        }
      : initialData,
    editInfo: {
      desiredDate: null,
      hallComment: null,
      authorContact: null,
    },
    validate: {},
  }

  // Initial form data
  const [formData, setFormData] = useState(initialDataByLocal)

  const [hallsData, changeHallsData] = useState({
    data: null,
    validate: {},
  })

  const isTemplateActive = !!formData.data.filter_id

  // Future: that how we take data from back when api start to exist
  useEffect(() => {
    if (id !== `new`) {
      // const date = new Date()
      // const timeOffset = date.getTimezoneOffset() * 60000
      betRequest({
        url: `request/${isCopy ? `copy` : `detail`}/${id}`,
        history,
        ultimateLogout,
      }).then(
        ({
          data: {
            filter,
            filter_id: filterId,
            desiredDate,
            hallComment,
            authorContact,
          },
        }) => {
          const gameData = filter.filters.type_game.game || []
          const utcGames = gameData.map(item => ({
            ...item,
            dttm_from: rangeData(item.dttm_from),
            dttm_to: rangeData(item.dttm_to),
          }))
          changeHallsData({ data: filter.filters.halls, validate: {} })
          setFormData({
            ...formData,
            data: {
              ...filter,
              filter_id: filterId,
              sms: {
                ...filter.sms,
                text: filter.sms.text === null ? null : `${filter.sms.text}`,
              },
              filters: {
                ...filter.filters,
                confines_period: {
                  ...filter.filters.confines_period,
                  from: rangeData(filter.filters.confines_period.from),
                  to: rangeData(filter.filters.confines_period.to),
                },
                type_game: {
                  ...filter.filters.type_game,
                  sport: !isKz
                    ? {
                        ...filter.filters.type_game.sport,
                        dttm_from: rangeData(
                          filter.filters.type_game.sport.dttm_from,
                        ),
                        dttm_to: rangeData(
                          filter.filters.type_game.sport.dttm_to,
                        ),
                      }
                    : { ...initialData.filters.type_game.sport },
                  game: utcGames,
                },
                win: !isKz
                  ? {
                      ...filter.filters.win,
                      dttm_from: rangeData(filter.filters.win.dttm_from),
                      dttm_to: rangeData(filter.filters.win.dttm_to),
                    }
                  : { ...initialData.filters.win },
                deposit: !isKz
                  ? {
                      ...filter.filters.deposit,
                      dttm_from: rangeData(filter.filters.deposit.dttm_from),
                      dttm_to: rangeData(filter.filters.deposit.dttm_to),
                    }
                  : { ...initialData.filters.deposit },
              },
            },
            editInfo: {
              desiredDate,
              hallComment,
              authorContact,
            },
          })
        },
      )
    }
  }, [])
  const changeSms = (fieldName, value, clearFilterId) => {
    toggleNumbers(false)
    const newValue = getNewValue(fieldName, value)
    const changeFilterId = clearFilterId ? { filter_id: null } : {}
    setFormData({
      ...formData,
      validate: {},
      data: {
        ...formData.data,
        sms: { ...formData.data.sms, ...newValue },
        ...changeFilterId,
      },
    })
  }
  const changeFilter = (fieldName, value, clearFilterId) => {
    toggleNumbers(false)
    const changeFilterId = clearFilterId ? { filter_id: null } : {}
    const newValue = getNewValue(fieldName, value)
    setFormData({
      ...formData,
      validate: {},
      data: {
        ...formData.data,
        ...changeFilterId,
        filters: {
          ...formData.data.filters,
          ...newValue,
        },
      },
    })
  }
  const changeSportField = (fieldName, value, clearFilterId) => {
    toggleNumbers(false)
    const changeFilterId = clearFilterId ? { filter_id: null } : {}
    const newValue = getNewValue(fieldName, value)
    setFormData({
      ...formData,
      validate: {},
      data: {
        ...formData.data,
        ...changeFilterId,
        filters: {
          ...formData.data.filters,
          type_game: {
            ...formData.data.filters.type_game,
            sport: {
              ...formData.data.filters.type_game.sport,
              ...newValue,
            },
          },
        },
      },
    })
  }
  const changeSecondLevelFilter = (
    levelOne,
    levelTwo,
    value,
    clearFilterId,
  ) => {
    toggleNumbers(false)
    const changeFilterId = clearFilterId ? { filter_id: null } : {}
    const newObj = {}
    if (isArray(levelTwo)) {
      levelTwo.forEach((item, key) => {
        newObj[item] = value[key]
      })
    } else {
      newObj[levelTwo] = value
    }
    setFormData({
      ...formData,
      validate: {},
      data: {
        ...formData.data,
        ...changeFilterId,
        filters: {
          ...formData.data.filters,
          [levelOne]: {
            ...formData.data.filters[levelOne],
            ...newObj,
          },
        },
      },
    })
  }
  const applyFilter = (newData, name) => {
    toggleNumbers(false)
    setTemplate({ name, id: newData.filter_id })
    const gameData = newData.filters.type_game.game || []
    const utcGames = gameData.map(item => ({
      ...item,
      dttm_from: rangeData(item.dttm_from),
      dttm_to: rangeData(item.dttm_to),
    }))
    changeHallsData({ ...hallsData, data: newData.filters.halls })
    setFormData({
      ...formData,
      validate: {},
      filter_id: newData.filter_id,
      data: {
        ...newData,
        sms: {
          ...newData.sms,
          text: newData.sms.text === null ? null : `${newData.sms.text}`,
          dttm_to_send: newData.sms.dttm_to_send,
        },
        filters: {
          ...newData.filters,
          confines_period: {
            ...newData.filters.confines_period,
            from: rangeData(newData.filters.confines_period.from),
            to: rangeData(newData.filters.confines_period.to),
          },
          type_game: {
            ...newData.filters.type_game,
            sport: !isKz
              ? {
                  ...newData.filters.type_game.sport,
                  dttm_from: rangeData(
                    newData.filters.type_game.sport.dttm_from,
                  ),
                  dttm_to: rangeData(newData.filters.type_game.sport.dttm_to),
                }
              : { ...initialData.filters.type_game.sport },
            game: utcGames,
          },
          win: !isKz
            ? {
                ...newData.filters.win,
                dttm_from: rangeData(newData.filters.win.dttm_from),
                dttm_to: rangeData(newData.filters.win.dttm_to),
              }
            : { ...initialData.filters.win },
          deposit: !isKz
            ? {
                ...newData.filters.deposit,
                dttm_from: rangeData(newData.filters.deposit.dttm_from),
                dttm_to: rangeData(newData.filters.deposit.dttm_to),
              }
            : { ...initialData.filters.deposit },
        },
      },
    })
  }

  const getSportFieldValue = fieldName =>
    formData.data.filters.type_game.sport[fieldName]
  const getSmsFieldValue = fieldName => formData.data.sms[fieldName]
  const getFilterFieldValue = fieldName => formData.data.filters[fieldName]
  const getSecondLevelFilter = (levelOne, levelTwo) =>
    formData.data.filters[levelOne][levelTwo]

  const resetFilter = () => {
    toggleNumbers(false)
    setFormData({
      ...formData,
      validate: {},
      data: isKz
        ? {
            ...initialData,
            filters: { ...initialData.filters, ...initialPlatformFiltersData },
            sms: { ...initialData.sms, ...initialPlatformSmsData },
          }
        : initialData,
    })
    changeHallsData({ data: null, validate: {} })
  }
  const changeGameValue = (value, clearFilterId) => {
    toggleNumbers(false)
    const changeFilterId = clearFilterId ? { filter_id: null } : {}
    setFormData({
      ...formData,
      data: {
        ...formData.data,
        ...changeFilterId,
        validate: {},
        filters: {
          ...formData.data.filters,
          type_game: {
            ...formData.data.filters.type_game,
            game: value,
          },
        },
      },
    })
  }
  const setValidate = errors => setFormData({ ...formData, validate: errors })
  return {
    validate: formData.validate,
    data: formData.data,
    editInfo: formData.editInfo,
    setValidate,
    notValid: !isEmpty(formData.validate),
    resetFilter,
    // for templates
    applyFilter,
    isTemplateActive,
    // sms
    getSmsFieldValue,
    changeSms,
    // filters
    getFilterFieldValue,
    getSecondLevelFilter,
    changeFilter,
    changeSecondLevelFilter,
    // sport
    getSportFieldValue,
    changeSportField,
    // games
    changeGameValue,
    formGames: formData.data.filters.type_game.game,
    // halls
    hallsData,
    changeHallsData,
  }
}

export default useForm

import React from "react"
import { Checkbox } from "antd"
import "./styles.css"

const CheckBoxComponent = ({ label, ...rest }) => {
  return (
    <div className="CheckboxWrapper">
      <Checkbox {...rest} />
      <div className="CheckboxText" style={{ paddingLeft: `12px` }}>
        {label}
      </div>
    </div>
  )
}

export default CheckBoxComponent

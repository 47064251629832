import betRequest from "api/request"
import { notification } from "antd"

const addPhonesApi = ({
  data,
  refreshList,
  setPhoneData,
  setModalWarning,
  phoneData,
  close,
  history,
  ultimateLogout,
}) => {
  betRequest({
    method: `post`,
    url: `blacklist/add-multiple`,
    requestBody: data,
    history,
    ultimateLogout,
  })
    .then(() => {
      refreshList()
      notification.success({
        message: "Номера успешно добавлены",
      })
      close()
    })
    .catch(errors => {
      if (errors.response.data.duplicates) {
        setPhoneData({
          ...phoneData,
          duplicates: errors.response.data.duplicates,
        })
        setModalWarning(true)
      } else {
        setPhoneData({
          ...phoneData,
          error: errors.response.data,
        })
        notification.error({
          message: "Ошибка валидации",
        })
      }
    })
}

export default addPhonesApi

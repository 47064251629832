import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Table } from "antd"
import { ButtonPrimary } from "Components/Button"
import Pagination from "Components/Pagination"
import betRequest from "api/request"
import get from "lodash/get"
import moment from "moment"
import "./styles.css"
import uuid from "uuid"

const getOrder = order => {
  if (order === `ascend`) return `asc`
  if (order === `descend`) return `desc`
  return null
}

const getDate = date =>
  date ? moment.utc(date).format(`YYYY-MM-DD HH:mm:ss`) : null

const columns = [
  {
    title: "НАЗВАНИЕ ФИЛЬТРА",
    key: "name",
    dataIndex: "name",
    sorter: true,
  },
  {
    title: "ТЕМА",
    key: "subject",
    dataIndex: "subject",
  },
  {
    title: "ДАТА СОЗДАНИЯ",
    key: "create_date",
    dataIndex: "create_date",
    render: date => {
      return <div>{getDate(date)}</div>
    },
  },
  {
    title: "ПОСЛЕДНЕЕ ИСПОЛЬЗОВАНИЕ",
    key: "last_use",
    dataIndex: "last_use",
    render: date => <div>{getDate(date)}</div>,
  },
  {
    title: "КОЛ-ВО ИСПОЛЬЗОВАНИЙ",
    key: "use_count",
    dataIndex: "use_count",
  },
]

const FiltersList = ({ applyFilter, ultimateLogout }) => {
  const history = useHistory()
  const [activeRow, setActiveRow] = useState(null)
  const [filterData, setFilterData] = useState({
    data: [],
    pending: false,
    total: 0,
  })
  const [requestParams, setRequestParams] = useState({
    page: 1,
    perPage: 50,
    sort: {},
  })
  useEffect(() => {
    setFilterData({ ...filterData, pending: true })
    betRequest({
      method: `post`,
      url: `filter/template/all`,
      requestBody: {
        limit: requestParams.perPage,
        offset: (requestParams.page - 1) * requestParams.perPage,
        sort: requestParams.sort,
      },
      history,
      ultimateLogout,
    })
      .then(({ data, headers }) => {
        const dataWithKeys = data.map(item => {
          return { key: uuid.v4(), ...item }
        })
        setFilterData({
          data: dataWithKeys,
          pending: false,
          total: headers[`total-count`],
        })
      })
      .catch(() => setFilterData({ ...filterData, pending: false }))
  }, [requestParams])

  return (
    <div className="FiltersListWrapper">
      <div>
        <Table
          columns={columns}
          dataSource={filterData.data}
          rowClassName={(record, index) =>
            index === activeRow ? `Row ActiveRow` : `Row`
          }
          loading={filterData.pending}
          scroll={{ y: `380px`, scrollToFirstRowOnChange: false }}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: () =>
                setActiveRow(
                  rowIndex !== activeRow || rowIndex === null ? rowIndex : null,
                ),
            }
          }}
          onChange={(pagination, filters, sorter) => {
            if (
              sorter.order !== requestParams.sort.order ||
              sorter.field !== requestParams.sort.field
            ) {
              setRequestParams({
                ...requestParams,
                sort: { order: getOrder(sorter.order), field: sorter.field },
              })
            }
          }}
          bordered
        />
      </div>
      <div className="ButtonWrapper">
        <ButtonPrimary
          disabled={activeRow === null}
          className="ButtonApply"
          type="primary"
          onClick={() => {
            const activeFilter = get(filterData, `data[${activeRow}]`)
            if (activeFilter) {
              applyFilter(
                {
                  ...activeFilter.filter,
                  filter_id: activeFilter.id,
                },
                activeFilter.name,
              )
            }
          }}
        >
          Применить
        </ButtonPrimary>
        <Pagination
          page={requestParams.page}
          perPage={requestParams.perPage}
          pending={filterData.pending}
          changePage={page =>
            setRequestParams({
              ...requestParams,
              page,
            })
          }
          total={filterData.total}
        />
      </div>
    </div>
  )
}

export default FiltersList

import isArray from "lodash/isArray"
import get from "lodash/get"
import betRequest from "api/request"
import { useEffect, useState } from "react"

// eslint-disable-next-line import/prefer-default-export
export const getNewValue = (fieldName, value) => {
  const newObj = {}
  if (isArray(fieldName)) {
    fieldName.forEach((item, key) => {
      newObj[item] = value[key]
    })
  } else {
    newObj[fieldName] = value
  }
  return newObj
}

const initialCheckClubs = {
  pending: false,
  check: true,
  addresses: [],
  allAddresses: {},
  clubs: [],
  errors: ``,
}

let waitClubs

export const useCheckClubNumbers = ({
  clubs,
  includeClosed,
  history,
  ultimateLogout,
  allowCheck,
}) => {
  const [checkClubs, setCheckClubs] = useState(initialCheckClubs)
  useEffect(() => {
    if (clubs && clubs.length && allowCheck) {
      clearTimeout(waitClubs)
      waitClubs = setTimeout(() => {
        setCheckClubs({ ...checkClubs, pending: true })
        betRequest({
          url: `request/check_clubs`,
          requestBody: {
            clubs: isArray(clubs) ? clubs : [clubs],
            include_closed: includeClosed,
          },
          ultimateLogout,
          history,
          method: `post`,
        })
          .then(
            ({
              data: { clubs: errorNumbers, check, addresses, allAddresses },
            }) =>
              setCheckClubs({
                pending: false,
                check,
                clubs: errorNumbers,
                addresses,
                allAddresses,
              }),
          )
          .catch(errors =>
            setCheckClubs({
              ...checkClubs,
              pending: false,
              errors: get(errors, `response.data.description`, ``),
            }),
          )
      }, 1000)
    } else if (!clubs || clubs === []) {
      setCheckClubs(initialCheckClubs)
    }
  }, [clubs, includeClosed])

  return checkClubs
}

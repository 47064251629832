import betRequest from "api/request"

const refreshTabCount = ({ history, ultimateLogout }) => {
  betRequest({
    url: `request/all/new`,
    history,
    method: `POST`,
    ultimateLogout,
  }).then(({ data }) => {
    document.title = `(${data || 0}) SMS`
  })
}

export default refreshTabCount

import betRequest from "api/request"
import { notification } from "antd"

const removeNumber = ({ refresh, id, number, history, ultimateLogout }) => {
  betRequest({
    method: `delete`,
    url: `blacklist/delete/${id}`,
    history,
    ultimateLogout,
  })
    .then(
      () => refresh(),
      notification.success({
        message: `Номер ${number} успешно удален`,
      }),
    )
    .catch(() =>
      notification.error({
        message: "Ошибка удаления",
      }),
    )
}

export default removeNumber

import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import useNow from "api/hooks"
import TableComponent from "Components/Table"
import { ButtonPrimary } from "Components/Button"
import Modal from "Components/Modal"
import { DeleteOutlined } from "@ant-design/icons"
import AddPhones from "./AddPhones"
import removeNumber from "./helper"
import "./styles.css"

const BlackList = ({ ultimateLogout }) => {
  const [refreshTimestamp, refreshList] = useNow()
  const history = useHistory()
  const columnsData = [
    {
      title: "Телефон",
      key: "number",
      dataIndex: "number",
    },
    {
      title: "Комментарий",
      key: "comment",
      dataIndex: "comment",
    },
    {
      title: "№ клуба",
      key: "hall",
      dataIndex: "hall",
    },
    {
      title: "Кто добавил",
      key: "added_person",
      dataIndex: "added_person",
    },
    {
      title: "Дата добавления",
      key: "added_date",
      dataIndex: "added_date",
      type: "date",
    },
    {
      title: "",
      key: "actions",
      fixed: "right",
      withoutSorter: true,
      withoutSearch: true,
      width: 30,
      render: ({ id, number }) => (
        <DeleteOutlined
          className="TableIcon"
          onClick={e => {
            e.stopPropagation()
            removeNumber({
              refresh: refreshList,
              id,
              number,
              history,
              ultimateLogout,
            })
          }}
        />
      ),
    },
  ]
  const [modalData, changeModalData] = useState({ show: false, status: null })
  const changeModalStatus = newStatus =>
    changeModalData({ ...modalData, status: newStatus })
  const closeModal = () => changeModalData({ show: false, status: null })
  const repeatModal = () => changeModalData({ show: true, status: null })
  const isError = modalData.status === `error`
  return (
    <div className="BlackListWrapper">
      <div className="ContentHeader">
        <h1>Черный список</h1>
        <div className="CreateRequestButtonWrapper">
          <ButtonPrimary
            onClick={() => changeModalData({ ...modalData, show: true })}
          >
            Добавить телефон
          </ButtonPrimary>
        </div>
      </div>
      <TableComponent
        name="blacklist-table"
        columnsData={columnsData}
        dataSource="blacklist"
        refresh={refreshTimestamp}
        ultimateLogout={ultimateLogout}
        fileDownloadName="Черный список"
      />
      {modalData.show && (
        <div className="addBlacklistModal">
          <Modal
            title="Добавить в черный список"
            width={`${modalData.status ? 512 : 784}px`}
            close={closeModal}
            status={modalData.status}
            statusMessage={
              modalData.status === `complete`
                ? `Номера успешно добавлены`
                : `Ошибка`
            }
          >
            <>
              {modalData.status ? (
                <>
                  <div style={{ paddingBottom: `28px` }}>
                    {isError ? `Что-то пошло не так, попробуйте еще раз` : ``}
                  </div>
                  <ButtonPrimary onClick={isError ? repeatModal : closeModal}>
                    {isError ? `Повторить` : `Хорошо`}
                  </ButtonPrimary>
                </>
              ) : (
                <AddPhones
                  changeModalStatus={changeModalStatus}
                  close={closeModal}
                  refreshList={refreshList}
                  ultimateLogout={ultimateLogout}
                />
              )}
            </>
          </Modal>
        </div>
      )}
    </div>
  )
}

export default BlackList

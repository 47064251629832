import React from "react"
import { InputNumber } from "antd"
import { RangePickerComponent } from "Components/DatePicker"
import "./styles.css"

const FromToInput = ({
  fromValue,
  changeFrom,
  toValue,
  changeTo,
  withDate,
  disabled,
  dateProps,
  error,
  inputError,
  pickerError,
}) => {
  const inputErrorClassName =
    error || inputError ? `InputFromToWrapperError` : ``
  return (
    <div className="FromToInputWithDateWrapper">
      <div className="FromToInputWrapper">
        <div
          className={
            withDate
              ? `FromWithDateInputWrapper ${inputErrorClassName}`
              : `FromInputWrapper ${inputErrorClassName}`
          }
        >
          <InputNumber
            placeholder="От"
            value={fromValue}
            disabled={disabled}
            onChange={value => changeFrom(value)}
            className={withDate ? `FromWithDateInput` : `FromInput`}
          />
        </div>
        <div
          className={
            withDate
              ? `ToWithDateInputWrapper ${inputErrorClassName}`
              : `ToInputWrapper ${inputErrorClassName}`
          }
        >
          <InputNumber
            placeholder="До"
            value={toValue}
            disabled={disabled}
            onChange={value => changeTo(value)}
            className={withDate ? `ToWithDateInput` : `ToInput`}
          />
        </div>
      </div>
      {withDate && (
        <div className="FromToDateWrapper">
          <RangePickerComponent
            error={pickerError}
            style={{ width: `100%` }}
            {...dateProps}
          />
        </div>
      )}
    </div>
  )
}

export default FromToInput

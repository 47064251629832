import React from "react"
import { ReactComponent as CloseIcon } from "Container/icons/close.svg"
import { ReactComponent as CompleteIcon } from "Container/icons/complete.svg"
import { ReactComponent as ErrorIcon } from "Container/icons/error.svg"
import GlobalEscapeHandle from "Components/GlobalEscapeHandle"
import "./styles.css"

const Modal = ({ close, title, status, statusMessage, width, children }) => {
  const completeIcon = status === `complete` ? <CompleteIcon /> : <div />
  const errorIcon = status === `error` ? <ErrorIcon /> : <div />
  const modalHeader = status ? statusMessage : title
  return (
    <div className="ModalWrapper">
      <div className="ModalBody" style={{ width }}>
        <div className="CloseWrapper">
          <div />
          <div className="CloseIcon" onClick={close}>
            <CloseIcon />
          </div>
        </div>
        <div className="ModalContentWrapper">
          {status && (
            <div>
              {completeIcon}
              {errorIcon}
            </div>
          )}
          {(title || statusMessage) && (
            <div className="ModalHeader">{modalHeader}</div>
          )}
          {children}
        </div>
      </div>
      <GlobalEscapeHandle onEscapePress={close} />
    </div>
  )
}

export default Modal

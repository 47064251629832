import React from "react"
import { Radio } from "antd"
import "./styles.css"

const RadioComponent = ({ disabled, children, ...rest }) => {
  return (
    <div className="RadioWrapper">
      <Radio disabled={disabled} {...rest}>
        {children}
      </Radio>
    </div>
  )
}

export default RadioComponent

import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Table } from "antd"
import ButtonDefault, { DownloadXlsButton } from "Components/Button"
import noop from "lodash/noop"
import Pagination from "Components/Pagination"
import PerPageComponent from "Components/PerPageComponent"
import moment from "moment"
import isArray from "lodash/isArray"
import find from "lodash/find"
import isEmpty from "lodash/isEmpty"
import { useDictionary, useDownload } from "api/hooks"
import Badge from "Components/Badge"
import useTable from "./useTable"
import "./styles.css"
import getColumnSearchProps from "./Search/getColumnSearch"

const DEFAULT_COLUMN_WIDTH = 130

const getCellComponent = (value, type, data) => {
  switch (type) {
    case `badge`: {
      const { color, background, name } =
        find(data, item => item && item.id === value) || []
      return <Badge color={color} background={background} title={name} />
    }
    case `date`: {
      return (
        <div>{value ? moment(value).format(`YYYY-MM-DD HH:mm:ss`) : null}</div>
      )
    }
    case `utc-date`: {
      return (
        <div>
          {value ? moment.utc(value).format(`YYYY-MM-DD HH:mm:ss`) : null}
        </div>
      )
    }
    default:
      return <div>{value}</div>
  }
}

/**
 * @param {array} data - TODO: mock data, remove and use api in useTable
 * @param {string} dataSource - part of url, source of data for Table
 * @param {array} columnsData - config for creating columns for table
 * * columnsData:
 * * * @param {string, string, number, type } title, name, indexId, type - basic params of columnsData
 * @param {string} scrollX - not required param, for table with many columns
 */

const TableComponent = ({
  name,
  data: mockData,
  fileDownloadName,
  tablePending,
  editComponent,
  extraRequestBody,
  filteredDownload,
  withDictionary,
  withoutReset,
  badgeData,
  saveSearch,
  dataSource,
  columnsData,
  scrollX,
  withoutSelect,
  withoutLoad,
  downloadConfig,
  withoutAll,
  fullWidth,
  refresh,
  ultimateLogout,
  setIsEmpty,
  ...rest
}) => {
  const history = useHistory()
  const initialSearch =
    saveSearch && localStorage.getItem(saveSearch)
      ? JSON.parse(localStorage.getItem(saveSearch))
      : {}
  const [search, setSearch] = useState(initialSearch)
  const [localSearch, setLocalSearch] = useState(initialSearch)
  const {
    data: { status: statusData },
    pending: dictionaryPending,
  } = useDictionary(withDictionary && !badgeData)
  const {
    tableData,
    setTableData,
    data,
    total,
    status,
    page,
    perPage,
    changePage,
    changePerPage,
    sort,
    changeSort,
  } = useTable({
    name,
    dataSource: withoutAll ? dataSource : `${dataSource}/all`,
    extraRequestBody,
    search,
    refresh,
    ultimateLogout,
    saveSearch,
    setIsEmpty,
  })

  const columns = columnsData.map(item => {
    const column = {
      title: item.title,
      dataIndex: item.dataIndex,
      key: item.key,
      width: item.width || DEFAULT_COLUMN_WIDTH,
      fixed: item.fixed,
      sorter: item.withoutSorter ? null : noop,
      sortDirections: ["descend", "ascend"],
      sortOrder: sort.field === item.key ? sort.order : null,
      render: item.render
        ? item.render
        : value => getCellComponent(value, item.type, badgeData || statusData),
    }
    return item.withoutSearch
      ? column
      : {
          ...column,
          ...getColumnSearchProps({
            name: item.key,
            type: item.type,
            badgeData,
            search,
            setSearch,
            localSearch,
            setLocalSearch,
            tableData,
            setTableData,
          }),
        }
  })

  const showSearchReset = (!isEmpty(search) || sort.field) && !withoutReset

  const { loadPending, downloadFile } = useDownload({
    method: downloadConfig?.method || `GET`,
    history,
    dataSource,
    requestBody: downloadConfig?.payload,
    fileName: fileDownloadName,
  })

  const withoutPagination = Boolean(perPage >= total)

  return (
    <div className="ComponentWrapper">
      <div className="HeaderWrapper">
        {showSearchReset && (
          <div style={{ paddingRight: `16px` }}>
            <ButtonDefault
              disabled={loadPending}
              loading={loadPending}
              onClick={() => {
                setSearch({})
                setLocalSearch({})
                if (saveSearch) localStorage.removeItem(saveSearch)
                changeSort({
                  order: undefined,
                  field: undefined,
                })
              }}
            >
              Сбросить
            </ButtonDefault>
          </div>
        )}
        {!withoutLoad && !filteredDownload && (
          <div className="XlsDownloadBtn">
            <ButtonDefault
              disabled={loadPending}
              loading={loadPending}
              onClick={downloadFile}
            >
              Выгрузить
            </ButtonDefault>
          </div>
        )}
        {!withoutLoad && filteredDownload && (
          <DownloadXlsButton
            statuses={badgeData}
            fileDownloadName={fileDownloadName}
            dataSource={dataSource}
          />
        )}
        {editComponent}
        <div style={{ paddingRight: fullWidth ? `16px` : `0` }}>
          <PerPageComponent
            pending={status.pending}
            perPage={perPage}
            changePerPage={changePerPage}
          />
        </div>
      </div>
      <div>
        <Table
          onChange={(pagination, filters, sorter) => {
            if (sorter.order !== sort.order || sorter.field !== sort.field) {
              setTableData({
                ...tableData,
                sort: { order: sorter.order, field: sorter.field },
                page: 1,
                perPage: 10,
              })
            }
          }}
          columns={columns}
          dataSource={isArray(data) ? data : []}
          pagination={false}
          showSorterTooltip={false}
          tableLayout="fixed"
          scroll={{
            x: scrollX,
            y: `calc(100vh - ${withoutPagination ? `265` : `335`}px)`,
          }}
          loading={status.pending || dictionaryPending || tablePending}
          bordered
          {...rest}
        />
      </div>
      {!withoutPagination && (
        <div
          className="PaginationWrapper"
          style={{ paddingRight: fullWidth ? `16px` : `0` }}
        >
          <Pagination
            page={page}
            perPage={perPage}
            pending={status.pending}
            changePage={changePage}
            total={total}
          />
        </div>
      )}
    </div>
  )
}

export default TableComponent

import React from "react"
import "./styles.css"

const Badge = ({ color, background, value, title }) => (
  <div
    style={{
      color,
      padding: `3px 10px`,
      marginRight: `8px`,
      borderRadius: `4px`,
      backgroundColor: `rgba(${background})`,
    }}
    className="BadgeComponent"
  >
    {`${title} ${value || value === 0 ? `(${value})` : ``}`}
  </div>
)

export default Badge

import { useState, useCallback, useEffect } from "react"
import betRequest from "api/request"

export const useDownload = ({
  history,
  method,
  dataSource,
  requestBody,
  fileName,
  ultimateLogout,
}) => {
  const [pending, setPending] = useState(false)
  const downloadFile = () => {
    setPending(true)
    betRequest({
      method,
      url: `${dataSource}/download`,
      history,
      ultimateLogout,
      requestBody,
    })
      .then(({ data: { link } }) => {
        const loadLink = document.createElement(`a`)
        loadLink.setAttribute(`href`, link)
        loadLink.setAttribute(`download`, `${fileName}.xlsx`)
        loadLink.style.display = `none`
        document.body.appendChild(loadLink)
        loadLink.click()
        document.body.removeChild(loadLink)
        setPending(false)
      })
      .catch(() => setPending(false))
  }
  return { loadPending: pending, downloadFile }
}

export const useDictionary = (useIt, history, ultimateLogout) => {
  const [dictionaryData, setDictionaryDara] = useState({
    data: {
      gateway: [],
      subject_type: [],
      guest_count: [],
      macroses: [],
      numbers_status: [],
      request_status: [],
      stop_words: [],
    },
    pending: false,
    error: false,
  })
  useEffect(() => {
    if (useIt || useIt === 0) {
      betRequest({ url: `dictionary`, method: `get`, history, ultimateLogout })
        .then(({ data }) => {
          setDictionaryDara({ ...dictionaryData, data, pending: false })
        })
        .catch(() =>
          setDictionaryDara({
            ...dictionaryData,
            pending: false,
            error: true,
          }),
        )
    }
  }, [useIt])

  return dictionaryData
}

const useNow = () => {
  const [timestamp, setTimestamp] = useState(Date.now())

  const refresh = useCallback(() => setTimestamp(Date.now()), [])

  return [timestamp, refresh]
}

export default useNow

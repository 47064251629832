import React, { useState, useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"
import { CaretLeftOutlined } from "@ant-design/icons"
import Badge from "Components/Badge"
import TableComponent from "Components/Table"
import ButtonDefault from "Components/Button"
import SelectComponent from "Components/Select"
import RequestPreviewModal from "Container/Request/Form/RightPanel/RequestPreview"

import find from "lodash/find"
import isEmpty from "lodash/isEmpty"
import moment from "moment"

import useNow, { useDictionary } from "api/hooks"

import getRequest, { getColumn, changeStatus } from "./helper"
import "./styles.css"

const getDateValue = value =>
  value ? moment(value).format(`YYYY-MM-DD HH:mm:ss`) : null

const getDateUtcValue = value =>
  value ? moment.utc(value).format(`YYYY-MM-DD HH:mm:ss`) : null

const RequestPreview = ({ ultimateLogout }) => {
  const [refreshTimestamp, refreshList] = useNow()
  const { id } = useParams()
  const isAdmin = localStorage.getItem(`role`) === `ADMIN`
  const isKz = localStorage.getItem(`local`) === `kz`
  const history = useHistory()

  const [requestValue, setRequestValue] = useState(null)
  const [numberValue, setNumberValue] = useState(null)

  const [requestData, setData] = useState({
    data: {},
    pending: false,
    status: null,
  })

  useEffect(() => {
    getRequest(
      requestData,
      setData,
      id,
      history,
      ultimateLogout,
      setRequestValue,
      setNumberValue,
      refreshTimestamp,
    )
  }, [id, refreshTimestamp])

  const { data, pending } = requestData

  const { data: dictionaryData, pending: dictionaryPending } = useDictionary(
    id,
    history,
    ultimateLogout,
  )

  const {
    numbers_status: dictionaryStatus = [],
    request_status: requestStatus = [],
  } = dictionaryData

  const previewStatus = (data.statuses || []).map(item => ({
    data: find(
      dictionaryStatus,
      findItem => findItem && findItem.id === item.id,
    ),
    count: item.count,
  }))

  const column = getColumn({ id, isKz })

  const [isEmptyTable, setIsEmpty] = useState(true)

  const requestOptions = requestStatus.map(item => ({
    value: item.id,
    label: item.name,
  }))

  const numberOptions = requestStatus.map(item => ({
    value: item.id,
    label: item.name,
  }))

  const [showPreviewModal, togglePreviewModal] = useState(false)
  const [statusPending, setStatusPending] = useState()
  return (
    <div className="RequestPreviewWrapper">
      <div className="BreadCrumbsWrapper">
        Заявки на рассылки <span style={{ fontSize: `10px` }}>»</span>{" "}
        {`Детали рассылки №${id}`}
      </div>
      <div className="FormTitle">
        <div className="BackIcon">
          <CaretLeftOutlined
            style={{ fontSize: "24px", color: "#92929d", cursor: "pointer" }}
            onClick={() => history.push(`/request`)}
          />
        </div>
        <div>{`Детали рассылки №${id}`}</div>
      </div>
      <div className="PreviewWrapper">
        <div className="PreviewDataWrapper">
          <div className="PreviewColumn">
            <div className="PreviewCeil">
              <div className="FormElementTitle">ТЕКСТ ЗАЯВКИ</div>
              <div className="TextOverflow">{data.request_text}</div>
            </div>
            <div className="PreviewCeil">
              <div className="FormElementTitle">КОММЕНТАРИИ КЛУБА</div>
              <div className="EditInfoValue">{data.club_comment}</div>
            </div>
            <div className="PreviewCeil">
              <div className="FormElementTitle">СОЗДАТЕЛЬ</div>
              <div>{data.creator}</div>
            </div>
            <div className="PreviewCeil">
              <div className="FormElementTitle">КОНТАКТНОЕ ЛИЦО</div>
              <div>{data.contact}</div>
            </div>
          </div>
          <div className="PreviewColumn">
            <div className="PreviewCeil">
              <div className="FormElementTitle">ТИП</div>
              <div>{!data.type ? `Групповая` : `На один клуб`}</div>
            </div>
            <div className="PreviewCeil">
              <div className="FormElementTitle">ДАТА СОЗДАНИЯ ЗАЯВКИ</div>
              <div>{getDateUtcValue(data.create_date)}</div>
            </div>
            <div className="PreviewCeil">
              <div className="FormElementTitle">ДАТА ОТПРАВКИ</div>
              <div>{getDateValue(data.send_date)}</div>
            </div>
            <div className="PreviewCeil">
              <div className="FormElementTitle">ШЛЮЗ</div>
              <div>{data.gateway}</div>
            </div>
          </div>
          <div className="PreviewColumn">
            {!isKz && (
              <div className="PreviewCeil">
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                <div className="FormElementTitle">ГЛАВНЫЙ КЛУБ ГРУППЫ</div>
                <div>{data.vip_parent}</div>
              </div>
            )}
            <div className="PreviewCeil">
              <div className="FormElementTitle">КОЛ-ВО СМС</div>
              <div>{data.sms_count}</div>
            </div>
            <div className="PreviewCeil">
              <div className="FormElementTitle">КОЛ-ВО НОМЕРОВ ПОЛУЧАТЕЛЕЙ</div>
              <div>{data.numbers_count}</div>
            </div>
            <div className="PreviewCeil">
              <div className="FormElementTitle">ИДЕНТИФИКАТОР РАССЫЛКИ</div>
              <div>{data.sending_id}</div>
            </div>
            <div className="PreviewCeil">
              <div className="FormElementTitle">НОМЕР КЛУБА</div>
              <div>{(data.club_number || []).join(`, `)}</div>
            </div>
          </div>
          <div className="PreviewColumn">
            {!isEmpty(data.history) && (
              <div className="PreviewCeil">
                <div className="FormElementTitle">ИСТОРИЯ</div>
                <div
                  style={{
                    maxHeight: `160px`,
                    overflowY: `scroll`,
                  }}
                >
                  {data.history.map(item => {
                    return (
                      <p key={uuidv4()}>
                        <span style={{ marginRight: `15px` }}>
                          {moment(item.date).format("DD MM YYYY HH:mm:ss")}
                        </span>
                        <span>{item.message}</span>
                      </p>
                    )
                  })}
                </div>
              </div>
            )}
            {isAdmin && data.request_status && (
              <div>
                <div className="PreviewCeil">
                  <SelectComponent
                    placeholder="Статус рассылки"
                    disabled={statusPending}
                    options={requestOptions}
                    style={{ width: `200px` }}
                    value={requestValue}
                    onChange={value =>
                      changeStatus({
                        type: `request`,
                        requestId: id,
                        statusId: value,
                        setPending: setStatusPending,
                        history,
                        ultimateLogout,
                        setItem: setRequestValue,
                        refresh: refreshList,
                      })
                    }
                  />
                </div>
                <div className="PreviewCeil">
                  <SelectComponent
                    placeholder="Статус номеров"
                    disabled={statusPending || isEmptyTable}
                    options={numberOptions}
                    style={{ width: `200px` }}
                    value={numberValue}
                    onChange={value =>
                      changeStatus({
                        type: `numbers`,
                        requestId: id,
                        statusId: value,
                        setPending: setStatusPending,
                        history,
                        ultimateLogout,
                        setItem: setNumberValue,
                        refresh: refreshList,
                      })
                    }
                  />
                </div>
              </div>
            )}
            {!!requestData.data.filter && (
              <div onClick={() => togglePreviewModal(!showPreviewModal)}>
                <ButtonDefault>Показать фильтр</ButtonDefault>
              </div>
            )}
          </div>
        </div>
        <div className="PreviewBadgesWrapper">
          {previewStatus.map(({ data: statusData = [], count }) => (
            <Badge
              color={statusData.color}
              background={statusData.background}
              title={statusData.name}
              value={count}
            />
          ))}
        </div>
      </div>
      <>
        <TableComponent
          name="request-numbers-table"
          fileDownloadName="Список номеров"
          refresh={refreshTimestamp}
          columnsData={column || []}
          ultimateLogout={ultimateLogout}
          history={history}
          dataSource="request/numbers"
          tablePending={dictionaryPending || pending}
          extraRequestBody={{ id: Number(id) }}
          badgeData={dictionaryStatus}
          withoutSelect
          withoutSearch
          withoutLoad={false}
          downloadConfig={{ method: `POST`, payload: { id } }}
          withoutAll
          setIsEmpty={setIsEmpty}
        />
      </>
      {showPreviewModal && (
        <RequestPreviewModal
          close={() => togglePreviewModal(!showPreviewModal)}
          dictionaryData={dictionaryData}
          data={requestData.data.filter}
          ultimateLogout={ultimateLogout}
          viewOnly
          id={id}
        />
      )}
    </div>
  )
}

export default RequestPreview

import React, { useState } from "react"
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons"
import "./styles.css"

const Card = ({ title, children }) => {
  const [showContent, toggleContent] = useState(true)
  return (
    <div className="CardWrapper">
      <div
        className="CardHeader"
        onClick={() => toggleContent(!showContent)}
        style={{
          borderBottom: showContent ? `solid 1px #e6e6e6` : `none`,
        }}
      >
        <div className="HeaderTitle">{title}</div>
        <div className="HeaderArrow">
          {showContent ? (
            <CaretDownOutlined style={{ color: `#92929d` }} />
          ) : (
            <CaretUpOutlined style={{ color: `#92929d` }} />
          )}
        </div>
      </div>
      {showContent ? <div className="CardContent">{children}</div> : <div />}
    </div>
  )
}

export default Card

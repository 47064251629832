import React from "react"
import { Radio } from "antd"
import RadioComponent from "Components/Radio"
import CheckBoxComponent from "Components/CheckBox"
import DatePickerComponent, {
  RangePickerComponent,
} from "Components/DatePicker"
import { TreeSelectComponent } from "Components/Select"
import get from "lodash/get"
import { CloseOutlined } from "@ant-design/icons"
import FromToInput from "Components/FromToInput"
import ButtonDefault from "Components/Button"
import "../styles.css"

// Initial data for one game
const newGameForm = {
  games: null,
  dttm: null,
  percentage_bets: 0,
  sum_from: null,
  sum_to: null,
  dttm_from: null,
  dttm_to: null,
  dttm_all: false,
  balance: 0,
}

const CurrentGame = ({
  game,
  gameKey,
  changeGames,
  deleteGame,
  options = [],
  currentValidate,
  disabledBetDatePicker,
  disabledBetDate,
}) => {
  const disableBetSum = !!game.percentage_bets
  const notFirst = gameKey !== 0
  const gamesOptions = options.map(item => ({
    title: item.name,
    value: item.id,
  }))

  const infoMsg = `Чтобы применился фильтр по играм, нужно выбрать хотя бы 1 игру`

  return (
    <div>
      <div className="GameRectangleWrapper">
        <div>№{gameKey + 1}</div>
        <div className="GameRectangle" />
        {notFirst && (
          <ButtonDefault
            icon={<CloseOutlined />}
            className="GameRectangleClose"
            onClick={() => deleteGame(gameKey)}
          />
        )}
      </div>
      <div className="PlateContent">
        <div className="PlateContentColumn">
          <div className="FormElementTitle">ВЫБЕРИТЕ ИГРУ</div>
          <div className="CurrentGame_gameSelect">
            <TreeSelectComponent
              treeData={gamesOptions}
              treeCheckable
              error={currentValidate.games}
              style={{ width: `100%` }}
              name="games"
              value={game.games || []}
              onChange={value => {
                // setGameState([])
                changeGames({
                  gameKey,
                  data: { ...game, games: value },
                  changeId: true,
                })
              }}
            />
            <div className="InfoText">{infoMsg}</div>
          </div>
          <div className="FormElementTitle">ДАТА ПОСЛЕДНЕЙ СТАВКИ</div>
          <div>
            <DatePickerComponent
              style={{ width: `100%` }}
              placeholder="Выберите дату"
              disabled={disabledBetDatePicker}
              disabledDate={disabledBetDate}
              error={currentValidate.dttm}
              name="dttm"
              value={game.dttm}
              onChange={value => {
                const newValue = value ? value.valueOf() : null
                changeGames({
                  gameKey,
                  data: { ...game, dttm: newValue },
                })
              }}
            />
          </div>
        </div>
        <div className="PlateContentColumn">
          <div className="FormElementTitle">ПРОЦЕНТ ОТ СТАВОК БОЛЕЕ</div>
          <Radio.Group
            name="percentage_bets"
            value={game.percentage_bets}
            onChange={({ target: { value } }) => {
              const newValue = {
                percentage_bets: value,
                sum_from: null,
                sum_to: null,
                dttm_from: null,
                dttm_to: null,
                dttm_all: false,
                balance: value ? 2 : game.balance,
              }
              changeGames({
                gameKey,
                data: { ...game, ...newValue },
                changeId: true,
              })
            }}
          >
            <RadioComponent value={0}>не учитывать</RadioComponent>
            <RadioComponent value={1}>10%</RadioComponent>
            <RadioComponent value={2}>20%</RadioComponent>
          </Radio.Group>
        </div>
        <div
          className="PlateContentColumn"
          style={{ textAlign: `center`, margin: `0 -16px` }}
        >
          <div className="FormElementTitle">ИЛИ</div>
        </div>
        <div className="PlateContentColumn">
          <div className="FormElementTitle">СУММА СТАВОК</div>
          <div style={{ padding: `0 0 12px 0` }}>
            <FromToInput
              name="sum"
              error={currentValidate.sum_from || currentValidate.sum_to}
              disabled={disableBetSum}
              fromValue={game.sum_from}
              changeFrom={value => {
                changeGames({
                  gameKey,
                  data: { ...game, sum_from: value },
                  changeId: true,
                })
              }}
              toValue={game.sum_to}
              changeTo={value => {
                changeGames({
                  gameKey,
                  data: { ...game, sum_to: value },
                  changeId: true,
                })
              }}
            />
          </div>
          <div className="FormElementTitle">ПЕРИОД СТАВКИ</div>
          <div>
            <RangePickerComponent
              disabled={disableBetSum || game.dttm_all}
              disabledDate={disabledBetDate}
              style={{ width: `100%`, minWidth: `240px` }}
              value={[game.dttm_from, game.dttm_to]}
              error={currentValidate.dttm_from || currentValidate.dttm_to}
              onChange={value => {
                const newValue = value
                  ? {
                      dttm_from: value[0].valueOf(),
                      dttm_to: value[1].valueOf(),
                    }
                  : { dttm_from: null, dttm_to: null }
                changeGames({ gameKey, data: { ...game, ...newValue } })
              }}
            />
          </div>
          <div style={{ paddingTop: `16px`, display: `flex` }}>
            <CheckBoxComponent
              label="За весь период"
              disabled={disableBetSum}
              checked={game.dttm_all}
              onChange={({ target: { checked } }) => {
                const newValue = {
                  dttm_all: checked,
                  dttm_from: null,
                  dttm_to: null,
                }
                changeGames({ gameKey, data: { ...game, ...newValue } })
              }}
            />
          </div>
        </div>
        <div className="PlateContentColumn">
          <div className="FormElementTitle">БАЛАНС</div>
          <Radio.Group
            name="balance"
            value={game.balance}
            onChange={({ target: { value } }) => {
              changeGames({
                gameKey,
                data: { ...game, balance: value },
                changeId: true,
              })
            }}
          >
            <RadioComponent disabled={!!game.percentage_bets} value={0}>
              Денежный
            </RadioComponent>
            <RadioComponent disabled={!!game.percentage_bets} value={1}>
              Бонусный
            </RadioComponent>
            <RadioComponent value={2}>Общий</RadioComponent>
          </Radio.Group>
        </div>
      </div>
    </div>
  )
}

const Games = ({
  formGames = [],
  changeGameValue,
  options,
  validate,
  disabledBetDatePicker,
  disabledBetDate,
}) => {
  const changeGame = ({ gameKey, data, changeId }) => {
    const newArray = formGames
    newArray[gameKey] = data
    changeGameValue(newArray, !!changeId)
  }
  const deleteGame = gameKey => {
    const cloneGameState = formGames.slice()
    cloneGameState.splice(gameKey, 1)
    changeGameValue(cloneGameState)
  }

  const noMoreGames = formGames.length === 10
  return (
    <div>
      <div>
        {formGames.map((item, key) => (
          <CurrentGame
            game={item}
            key={`${item}_${item.length}`}
            disabledBetDatePicker={disabledBetDatePicker}
            disabledBetDate={disabledBetDate}
            changeGames={changeGame}
            deleteGame={deleteGame}
            gameKey={key}
            options={options}
            currentValidate={get(validate, `game[${key}]`, {})}
          />
        ))}
      </div>
      <div className="AddButtonWrapper">
        <ButtonDefault
          disabled={noMoreGames}
          onClick={() => {
            changeGameValue([...formGames, newGameForm])
          }}
        >
          Добавить фильтр
        </ButtonDefault>
      </div>
    </div>
  )
}

export default Games

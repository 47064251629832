import React from "react"
import { DatePicker } from "antd"
import moment from "moment"
import "moment/locale/ru"
import locale from "antd/es/date-picker/locale/ru_RU"
import "antd/dist/antd.css"
import "./styles.css"

const { RangePicker } = DatePicker

const dateField = (value, checkOffset) => {
  const trueTime = checkOffset ? moment(value) : moment.utc(value)
  return value ? trueTime : null
}
const rangeField = value =>
  value[0] ? [moment(value[0]), moment(value[1])] : null

const DatePickerComponent = ({ value, error = false, showTime, ...rest }) => (
  <div className={`DatePickerWrapper ${error ? `ErrorPickerWrapper` : ``}`}>
    <DatePicker
      value={dateField(value, !!showTime)}
      {...rest}
      locale={locale}
      showTime={showTime}
    />
    {!!error && <div className="ErrorText">{error}</div>}
  </div>
)
export const RangePickerComponent = ({
  value,
  error = false,
  onChange,
  ...rest
}) => (
  <div
    className={`RangePickerWrapper ${error ? `ErrorRangePickerWrapper` : ``}`}
  >
    <RangePicker
      value={rangeField(value)}
      style={{ width: `100%` }}
      locale={locale}
      onChange={changeData => {
        if (changeData) {
          const start = changeData[0].startOf(`day`)
          const end = changeData[1].endOf(`day`)
          onChange([start, end])
        } else onChange(changeData)
      }}
      {...rest}
    />
    {!!error && <div className="ErrorText">{error}</div>}
  </div>
)

export default DatePickerComponent

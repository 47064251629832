import React from "react"
import { Input } from "antd"
import MaskedInput from "antd-mask-input"
import "./styles.css"

const InputComponent = ({ placeholder, error = false, ...rest }) => (
  <div className={`InputWrapper ${error ? `ErrorInputWrapper` : ``}`}>
    <Input placeholder={placeholder} {...rest} />
    {!!error && <div className="ErrorText">{error}</div>}
  </div>
)

export const InputArea = ({ placeholder, error = false, ...rest }) => (
  <div className={`InputWrapper ${error ? `ErrorInputWrapper` : ``}`}>
    <Input.TextArea placeholder={placeholder} {...rest} />
    {!!error && <div className="ErrorText">{error}</div>}
  </div>
)

export const InputWithMask = ({ placeholder, error = false, ...rest }) => (
  <div className={`InputWrapper ${error ? `ErrorInputWrapper` : ``}`}>
    <MaskedInput placeholder={placeholder} {...rest} />
    {!!error && <div className="ErrorText">{error}</div>}
  </div>
)

export default InputComponent

import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { ButtonDefault, ButtonPrimary } from "Components/Button"
import { InputArea } from "Components/Input"
import "./styless.css"
import CheckBoxComponent from "Components/CheckBox"
import uniq from "lodash/uniq"
import SelectComponent from "Components/Select"
import Modal from "Components/Modal"
import addPhonesApi from "./helper"

/**
 * Creating html pairs (hall - phones) from object with duplicates
 */
const formatDuplicates = duplicatesObj => {
  return Object.entries(duplicatesObj).map(([hall, phoneArr]) => {
    const allNetHallMsg = parseInt(hall, 10) === 0
    return (
      <div className="addBlacklistDupWarningModalRowWrapper" key={hall}>
        <div className="addBlacklistDupWarningModal_club">
          {allNetHallMsg ? "По всей сети: " : `Клуб ${hall}:`}
        </div>
        <div className="addBlacklistDupWarningModal_phone">
          {phoneArr.join(", ")}
          <br />
        </div>
      </div>
    )
  })
}

const DUPLICATES_FOUND_MSG = "Найдены дубликаты!"

const AddPhones = ({ close, refreshList, ultimateLogout }) => {
  const history = useHistory()
  const [phoneData, setPhoneData] = useState({
    data: {
      phones: [],
      halls: [],
      all_halls: false,
      comment: null,
    },
    error: {},
    duplicates: {},
  })

  const [modalWarning, setModalWarning] = useState(false)

  const errorHalls = phoneData.error.halls ? phoneData.error.halls : null
  const errorPhones = phoneData.error.phones ? phoneData.error.phones : null
  const errorComment = phoneData.error.comment ? phoneData.error.comment : null

  const getHallsSelectClassName = phoneData.data.halls.length
    ? "SelectClubsWrapper"
    : "EmptyInput SelectClubsWrapper"

  const getPhonesSelectClassName = phoneData.data.phones.length
    ? "SelectClubsWrapper"
    : "EmptyInput SelectClubsWrapper"

  const getCommentInputClassName = phoneData.data.comment
    ? "SelectClubsWrapper"
    : "EmptyInput SelectClubsWrapper"

  const hallsSelectOnChangeHandler = value => {
    if (value.length) {
      const lastEnter = value
        .pop()
        .split(`,`)
        .map(el => el.replace(/\D/g, ""))
        .filter(el => el)
      const newValue = uniq([...value, ...lastEnter])
      setPhoneData({
        ...phoneData,
        data: { ...phoneData.data, halls: newValue },
      })
    } else {
      setPhoneData({
        ...phoneData,
        data: { ...phoneData.data, halls: [] },
      })
    }
  }

  const phonesSelectOnChangeHandler = value => {
    if (value.length) {
      const lastEnter = value
        .pop()
        .split(`,`)
        .map(el => el.replace(/\D/g, ""))
        .filter(el => el)
        .filter(el => el)
      const newValue = uniq([...value, ...lastEnter])
      setPhoneData({
        ...phoneData,
        data: { ...phoneData.data, phones: newValue },
      })
    } else {
      setPhoneData({
        ...phoneData,
        data: { ...phoneData.data, phones: [] },
      })
    }
  }

  const commentInputOnChangeHandler = ({ target: { value } }) => {
    const cloneData = phoneData.data
    cloneData.comment = value
    setPhoneData({
      ...phoneData,
      data: cloneData,
    })
  }

  const allHallsCheckBoxOnChangeHandler = ({ target: { checked } }) => {
    setPhoneData({
      ...phoneData,
      data: {
        ...phoneData.data,
        all_halls: checked,
      },
    })
  }

  return (
    <div className="AddPhonesComponentWrapper">
      <div className="AddPhonesFormWrapper">
        <div>
          <div>
            <div className="FieldLabel">Номера телефонов</div>
            <SelectComponent
              placeholder="Введите номера телефонов через запятую"
              name="phones"
              error={errorPhones}
              className={getPhonesSelectClassName}
              mode="tags"
              value={phoneData.data.phones}
              onChange={phonesSelectOnChangeHandler}
            />
          </div>
          <div>
            <CheckBoxComponent
              label="Заблокировать номера по всей сети"
              name="all_halls"
              checked={phoneData.data.all_halls}
              onChange={allHallsCheckBoxOnChangeHandler}
            />
          </div>
          <div>
            <div className="FieldLabel">Клубы</div>
            <SelectComponent
              placeholder="Введите номера клубов через запятую"
              name="halls"
              error={errorHalls}
              className={getHallsSelectClassName}
              mode="tags"
              value={phoneData.data.halls}
              onChange={hallsSelectOnChangeHandler}
            />
          </div>
          <div>
            <div className="FieldLabel">Комментарий</div>
            <InputArea
              name="comment"
              placeholder="Укажите причину блокировки"
              className={getCommentInputClassName}
              error={errorComment}
              value={phoneData.data.comment}
              onChange={commentInputOnChangeHandler}
              rows={6}
            />
          </div>
        </div>
      </div>
      <div className="RowWrapper" style={{ paddingTop: `24px` }}>
        <ButtonPrimary
          style={{ marginRight: `16px` }}
          onClick={() =>
            addPhonesApi({
              data: phoneData.data,
              refreshList,
              setPhoneData,
              setModalWarning,
              phoneData,
              close,
              history,
              ultimateLogout,
            })
          }
        >
          Добавить
        </ButtonPrimary>
        <ButtonDefault onClick={close}>Отменить</ButtonDefault>
      </div>
      {modalWarning && (
        <div className="addBlacklistDupWarningModal">
          <Modal
            title={DUPLICATES_FOUND_MSG}
            width="500px"
            close={() => setModalWarning(false)}
          >
            <>
              <div className="addBlacklistDupWarningModalInfoWrapper">
                {formatDuplicates(phoneData.duplicates)}
              </div>
            </>
          </Modal>
        </div>
      )}
    </div>
  )
}

export default AddPhones

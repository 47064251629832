import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import betRequest from "api/request"
import uuid from "uuid"

/**
 * @param {string} dataSource - part of url, source of data for Table
 * @param {object} search - current table search data format [field_name]: search_value
 * @returns {object} page, perPage, sort, data, status, actions - all stuff for working with table
 */

const getOrder = order => {
  if (order === `ascend`) return `asc`
  if (order === `descend`) return `desc`
  return null
}

const useTable = ({
  name,
  dataSource,
  search,
  refresh,
  extraRequestBody,
  ultimateLogout,
  saveSearch,
  setIsEmpty,
}) => {
  const TABLE_FILTER_SETTINGS_NAME = name || "table_filter_settings"

  const filterSettingsStorage = localStorage.getItem(TABLE_FILTER_SETTINGS_NAME)

  const filterSettings =
    filterSettingsStorage && name ? JSON.parse(filterSettingsStorage) : null

  const [tableData, setTableData] = useState({
    status: {
      pending: false,
      error: false,
      completed: true,
    },
    data: [],
    page: filterSettings ? filterSettings.page : 1,
    perPage: filterSettings ? filterSettings.perPage : 10,
    total: 0,
    sort: filterSettings
      ? filterSettings.sort
      : {
          order: undefined,
          field: undefined,
        },
  })
  const history = useHistory()

  const changePage = value => setTableData({ ...tableData, page: value })
  const changeSort = value =>
    setTableData({ ...tableData, sort: value, page: 1 })
  const changePerPage = value =>
    setTableData({ ...tableData, page: 1, perPage: value })

  const requestBody = {
    ...extraRequestBody,
    limit: tableData.perPage,
    offset: (tableData.page - 1) * tableData.perPage,
    sort: tableData.sort.order
      ? { ...tableData.sort, order: getOrder(tableData.sort.order) }
      : undefined,
    search,
  }

  useEffect(() => {
    setTableData({
      ...tableData,
      status: { pending: true, error: false, completed: false },
    })

    localStorage.setItem(
      TABLE_FILTER_SETTINGS_NAME,
      JSON.stringify({
        page: tableData.page,
        perPage: tableData.perPage,
        sort: tableData.sort,
      }),
    )
    if (saveSearch) localStorage.setItem(saveSearch, JSON.stringify(search))

    betRequest({
      method: `post`,
      url: dataSource,
      requestBody,
      history,
      ultimateLogout,
    })
      .then(({ data, headers }) => {
        const dataWithUniqueKeys = data.map(item => {
          return { key: uuid.v4(), ...item }
        })
        if (setIsEmpty && data && data.length) {
          setIsEmpty(false)
        }
        setTableData({
          ...tableData,
          total: headers[`total-count`] || tableData.total,
          data,
          dataWithUniqueKeys,
          status: { pending: false, error: false, completed: true },
        })
      })
      .catch(() =>
        setTableData({
          ...tableData,
          status: { pending: false, error: true, completed: false },
        }),
      )
  }, [
    refresh,
    dataSource,
    tableData.page,
    tableData.perPage,
    tableData.sort,
    tableData.search,
    search,
  ])

  return {
    ...tableData,
    changePage,
    changePerPage,
    changeSort,
    tableData,
    setTableData,
  }
}

export default useTable

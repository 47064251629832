import React from "react"
import useNow from "api/hooks"
import TableComponent from "Components/Table"
import { DeleteOutlined } from "@ant-design/icons"
import betRequest from "api/request"
import "./styles.css"

const FilterStatistic = ({ ultimateLogout }) => {
  const [refreshTimestamp, refreshList] = useNow()
  const columnsData = [
    {
      title: "Название фильтра",
      key: "name",
      dataIndex: "name",
      withoutSorter: true,
    },
    {
      title: "Тема",
      key: "subject",
      dataIndex: "subject",
      withoutSorter: true,
    },
    {
      title: "Кол-во использований",
      key: "use_count",
      dataIndex: "use_count",
      withoutSearch: true,
      withoutSorter: true,
    },
    {
      title: "Дата создания",
      key: "create_date",
      dataIndex: "create_date",
      type: "date",
    },
    {
      title: "Последнее использование",
      key: "last_use",
      dataIndex: "last_use",
      type: "date",
    },
    {
      title: "",
      key: "actions",
      fixed: "right",
      withoutSorter: true,
      withoutSearch: true,
      width: 30,
      render: ({ id }) => (
        <DeleteOutlined
          className="TableIcon"
          onClick={e => {
            e.stopPropagation()
            betRequest({
              method: `delete`,
              url: `filter/template/delete/${id}`,
              ultimateLogout,
            }).then(() => refreshList())
          }}
        />
      ),
    },
  ]
  return (
    <div className="FilterStatisticWrapper">
      <div className="ContentHeader FilterStatisticHeaderWrapper">
        <h1>Статистика по фильтрам</h1>
      </div>
      <TableComponent
        name="template-table"
        columnsData={columnsData}
        dataSource="filter/template"
        refresh={refreshTimestamp}
        fileDownloadName="Список шаблонов фильтров"
        ultimateLogout={ultimateLogout}
      />
    </div>
  )
}

export default FilterStatistic

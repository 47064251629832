import React, { useState, useEffect, useRef } from "react"
import TableComponent from "Components/Table"
import ButtonDefault, { ButtonPrimary } from "Components/Button"
import { ReactComponent as SettingsIcon } from "Container/icons/settings.svg"
import Modal from "Components/Modal"
import { Popover, Tooltip } from "antd"
import { useHistory } from "react-router-dom"
import filter from "lodash/filter"
import find from "lodash/find"
import useNow, { useDictionary } from "api/hooks"
import CheckBoxComponent from "Components/CheckBox"
import { ReactComponent as UturnLeftIcon } from "Container/icons/arrow_uturn_left.svg"
import { ReactComponent as CheckCircleIcon } from "Container/icons/check_circle.svg"
import { ReactComponent as BlockIcon } from "Container/icons/block.svg"
import { ReactComponent as EditIcon } from "Container/icons/edit.svg"
import { ReactComponent as CopyBigIcon } from "Container/icons/copyBig.svg"
import changeStatus, { hasStopWords } from "./helper"
import "./style.css"

export const SENDED_SMS_TYPE = 5

const Request = ({ ultimateLogout }) => {
  const history = useHistory()
  const [refreshTimestamp, refreshList] = useNow()
  const isAdmin = localStorage.getItem(`role`) === `ADMIN`
  const timerToClearSomewhere = useRef(0)
  const isKz = localStorage.getItem(`local`) === `kz`

  // refresh data any 1,5 minutes
  useEffect(() => {
    timerToClearSomewhere.current = setInterval(() => refreshList(), 100000)

    return () => {
      clearInterval(timerToClearSomewhere.current)
    }
  }, [])
  const [statusModal, setStatusModal] = useState({ show: false, data: {} })
  const closeModal = () => setStatusModal({ show: false, data: {} })
  const {
    data: { request_status: dictionaryStatus, stop_words: stopWords },
    pending: dictionaryPending,
  } = useDictionary(true, history, ultimateLogout)
  const dateLastSendColumn = isKz
    ? [
        {
          title: "Всего разослано СМС",
          key: "sms_send_count",
          dataIndex: "sms_send_count",
          width: 123,
          withoutSorter: true,
          withoutSearch: true,
        },
      ]
    : [
        {
          title: "Дата последней ставки",
          key: "last_bet_date",
          dataIndex: "last_bet_date",
          width: 150,
          withoutSorter: true,
          withoutSearch: true,
        },
      ]
  const dateSmsModerationText = [
    {
      title: "Модерация текста",
      key: "moderation_text",
      dataIndex: "moderation_text",
      width: 180,
      withoutSorter: true,
      render: (moderationText, record) => {
        const text = isKz ? moderationText : record.sms_moderate_text
        const isLongText = text && text.length > 50
        return (
          <div>
            {isLongText ? (
              <Tooltip
                title={() => (
                  <div onClick={e => e.stopPropagation()}>{text}</div>
                )}
              >
                <div
                  className="TableLongText"
                  onClick={e => e.stopPropagation()}
                >
                  <div>{text}</div>
                </div>
              </Tooltip>
            ) : (
              <div>{text}</div>
            )}
          </div>
        )
      },
    },
  ]

  const initialColumns = [
    {
      title: "ID",
      key: "id",
      dataIndex: "id",
      fixed: "left",
      width: 70,
      withoutSorter: true,
    },
    {
      title: "Статус",
      key: "status",
      dataIndex: "status",
      type: "badge",
      fixed: "left",
      width: 180,
    },
    {
      title: "№ клуба",
      key: "club",
      dataIndex: "club",
      fixed: "left",
      width: 110,
      withoutSorter: true,
      render: (clubs, record) => (
        <Tooltip
          title={
            <div onClick={e => e.stopPropagation()}>
              {record.clubInfo
                ? record.clubInfo.map(item => (
                    <div key={`hall_info_${item.club}`}>{`${
                      item.club
                    }: ${item.address || `-`}`}</div>
                  ))
                : clubs}
            </div>
          }
          onClick={e => e.stopPropagation()}
          overlayClassName="TableClubDataTooltip"
        >
          <div className="TableLongText">
            <div style={{ maxWidth: `100px`, overflow: `auto` }}>{clubs}</div>
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Данные клуба",
      key: "club_data",
      dataIndex: "club_data",
      withoutSorter: true,
      withoutSearch: !!isKz,
      width: 180,
      render: clubData => (
        <Tooltip
          title={
            clubData && clubData.length > 50 ? (
              <div onClick={e => e.stopPropagation()}>{clubData}</div>
            ) : (
              ``
            )
          }
          onClick={e => e.stopPropagation()}
          overlayClassName="TableClubDataTooltip"
        >
          <div className="TableLongText">
            <div>{clubData}</div>
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Шлюз",
      key: "gateway",
      dataIndex: "gateway",
      width: 90,
      withoutSearch: true,
      withoutSorter: true,
    },
    ...dateSmsModerationText,
    {
      title: "Текст СМС / Комментарий",
      key: "comment",
      dataIndex: "comment",
      width: 180,
      withoutSorter: true,
      render: (comment, record) => {
        const text = isKz ? comment : record.sms_text
        const isLongText = text && text.length > 50
        return (
          <div>
            {isLongText ? (
              <Tooltip
                title={() => (
                  <div onClick={e => e.stopPropagation()}>{text}</div>
                )}
              >
                <div
                  className="TableLongText"
                  onClick={e => e.stopPropagation()}
                >
                  <div>{text}</div>
                </div>
              </Tooltip>
            ) : (
              <div>{text}</div>
            )}
          </div>
        )
      },
    },
    {
      title: "Кол-во СМС",
      key: "sms_count",
      dataIndex: "sms_count",
      withoutSearch: true,
      withoutSorter: true,
      width: 80,
    },
    !isKz
      ? {
          title: "ID - тикета",
          key: "ticket_id",
          dataIndex: "ticket_id",
          withoutSorter: true,
          render: id => <div onClick={e => e.stopPropagation()}>{id}</div>,
        }
      : {
          title: "Кол-во символов в СМС",
          key: "sms_symbol_count",
          dataIndex: "sms_symbol_count",
          withoutSearch: true,
          withoutSorter: true,
          width: 131,
          render: (smsSymbolCount, { sms_text: smsText }) => {
            return <div>{smsText ? smsText.length : ``}</div>
          },
        },
    {
      title: "Кол-во кошельков",
      key: "pocket_count",
      dataIndex: "pocket_count",
      withoutSearch: true,
    },
    ...dateLastSendColumn,
    {
      title: "Комментарий клуба",
      key: "club_comment",
      dataIndex: "club_comment",
      width: 180,
      withoutSorter: true,
      render: clubComment => {
        const isLongText = clubComment && clubComment.length > 50
        const text = isLongText
          ? `${clubComment.substr(0, 90)}...`
          : clubComment
        return (
          <div>
            {isLongText ? (
              <Tooltip
                title={() => (
                  <div onClick={e => e.stopPropagation()}>{clubComment}</div>
                )}
              >
                <div
                  className="TableLongText"
                  onClick={e => e.stopPropagation()}
                >
                  <div>{text}</div>
                </div>
              </Tooltip>
            ) : (
              <div>{text}</div>
            )}
          </div>
        )
      },
    },
    {
      title: "Желаемая дата отправки",
      key: "favorite_send_date",
      dataIndex: "favorite_send_date",
      type: "date",
      withoutSearch: true,
    },
    {
      title: "Дата заявки",
      key: "request_date",
      dataIndex: "request_date",
      type: "utc-date",
    },
    {
      title: "Отложенная рассылка",
      key: "send_after",
      dataIndex: "send_after",
      type: "date",
      width: 150,
    },
    {
      title: "Дата рассылки",
      key: "send_date",
      dataIndex: "send_date",
      type: "date",
      width: 150,
    },
    {
      title: "",
      key: "actions",
      fixed: "right",
      withoutSorter: true,
      withoutSearch: true,
      width: isAdmin ? 120 : 80,
      render: ({ id, status, moderation_text: moderationText }) => {
        return (
          <>
            <div className="TableIconWrapper">
              <div
                className={
                  hasStopWords(moderationText, stopWords)
                    ? "TableIconWrapper_Disabled"
                    : ""
                }
              >
                {(status === 1 || status === 8) && (
                  <Tooltip
                    placement="topRight"
                    title={
                      !hasStopWords(moderationText, stopWords)
                        ? `Перевести в статус ${
                            isKz ? `"Согласовано"` : `"Готово"`
                          }`
                        : `В тексте присутствует некорректная формулировка`
                    }
                  >
                    <div
                      className="ActionIconWrapper"
                      onClick={e => {
                        e.stopPropagation()
                        if (!hasStopWords(moderationText, stopWords)) {
                          const currentStatus = find(
                            dictionaryStatus,
                            item => item.id === 2,
                          )
                          setStatusModal({
                            show: true,
                            data: {
                              newStatusId: 2,
                              type: `complete`,
                              id,
                              message: `Вы уверены, что хотите перевести заявку №${id} в статус "${
                                currentStatus
                                  ? currentStatus.name
                                  : `Согласована`
                              }"?`,
                            },
                          })
                        }
                      }}
                    >
                      <CheckCircleIcon className="ActionIcon" />
                    </div>
                  </Tooltip>
                )}
              </div>
              {(status === 1 || status === 2 || (isKz && status === 8)) && (
                <Tooltip
                  placement="topRight"
                  title={`Перевести в статус ${
                    isKz ? `"Отказано"` : `отменена`
                  }`}
                >
                  <div
                    className="ActionIconWrapper"
                    onClick={e => {
                      e.stopPropagation()
                      const currentStatus = find(
                        dictionaryStatus,
                        item => item.id === (isKz ? 7 : 3),
                      )
                      setStatusModal({
                        show: true,
                        data: {
                          newStatusId: isKz ? 7 : 3,
                          type: `error`,
                          id,
                          message: `Вы уверены, что хотите перевести заявку №${id} в статус "${
                            currentStatus
                              ? currentStatus.name
                              : `${isKz ? `Отказано` : `Отклонена`}`
                          }?"`,
                        },
                      })
                    }}
                  >
                    <BlockIcon className="ActionIcon" />
                  </div>
                </Tooltip>
              )}
              {(status === 1 || status === 2 || (isKz && status === 8)) && (
                <Tooltip placement="topRight" title="Отредактировать">
                  <div
                    className="ActionIconWrapper"
                    onClick={e => {
                      e.stopPropagation()
                      history.push(`request/${id}`)
                    }}
                  >
                    <EditIcon className="ActionIcon" />
                  </div>
                </Tooltip>
              )}
              {(status === 3 ?? !isKz) && (
                <Tooltip
                  placement="topRight"
                  title={`Перевести в статус "На согласовании"`}
                >
                  <div
                    className="ActionIconWrapper"
                    onClick={e => {
                      e.stopPropagation()
                      const currentStatus = find(
                        dictionaryStatus,
                        item => item.id === 1,
                      )
                      setStatusModal({
                        show: true,
                        data: {
                          newStatusId: 1,
                          type: `error`,
                          id,
                          message: `Вы уверены, что хотите перевести заявку №${id} в статус "${
                            currentStatus
                              ? currentStatus.name
                              : `На согласовании`
                          }?"`,
                        },
                      })
                    }}
                  >
                    <UturnLeftIcon className="ActionIcon" />
                  </div>
                  {/* <StopOutlined */}
                  {/*    className="TableIcon" */}
                  {/*    onClick={e => { */}
                  {/*      e.stopPropagation() */}
                  {/*      const currentStatus = find( */}
                  {/*          dictionaryStatus, */}
                  {/*          item => item.id === 1, */}
                  {/*      ) */}
                  {/*      setStatusModal({ */}
                  {/*        show: true, */}
                  {/*        data: { */}
                  {/*          newStatusId: 1, */}
                  {/*          type: `error`, */}
                  {/*          id, */}
                  {/*          message: `Вы уверены, что хотите перевести заявку №${id} в статус "${currentStatus ? currentStatus.name: `На согласовании`}?"`, */}
                  {/*        }, */}
                  {/*      }) */}
                  {/*    }} */}
                  {/* /> */}
                </Tooltip>
              )}
              {isAdmin && (
                <Tooltip placement="left" title="Скопировать заявку">
                  <div
                    className="ActionIconWrapper"
                    onClick={e => {
                      e.stopPropagation()
                      history.push(`request/copy/${id}`)
                    }}
                  >
                    <CopyBigIcon className="ActionIcon" />
                  </div>
                </Tooltip>
              )}
            </div>
          </>
        )
      },
    },
  ]

  const activeColumns = localStorage.getItem(`activeColumns`)
    ? JSON.parse(localStorage.getItem(`activeColumns`))
    : null

  const [withoutColumns, setWithoutColumns] = useState(
    activeColumns || {
      id: true,
      status: true,
      club: true,
      club_data: true,
      gateway: true,
      moderation_text: true,
      comment: true,
      last_bet_date: true,
      sms_count: true,
      sms_symbol_count: true,
      pocket_count: true,
      ticket_id: true,
      request_text: true,
      club_comment: true,
      sms_send_count: true,
      favorite_send_date: true,
      request_date: true,
      send_after: true,
      send_date: true,
      actions: true,
    },
  )

  const popupContent = () => {
    return (
      <div>
        {initialColumns.map(item => (
          <CheckBoxComponent
            key={`${item.key}_${item.length}`}
            label={item.title || `Действия`}
            checked={withoutColumns[item.key]}
            onChange={({ target: { checked } }) => {
              const checkedData = { ...withoutColumns, [item.key]: checked }
              localStorage.setItem(`activeColumns`, JSON.stringify(checkedData))
              setWithoutColumns(checkedData)
            }}
          />
        ))}
      </div>
    )
  }
  const editColumns = filter(initialColumns, item => !!withoutColumns[item.key])

  return (
    <div className="RequestWrapper">
      <div className="ContentHeader">
        <h1>Заявки на рассылки</h1>
        <div className="CreateRequestButtonWrapper">
          <ButtonPrimary
            className="CreateRequestButton"
            onClick={() => history.push(`/request/new`)}
          >
            Создать рассылку
          </ButtonPrimary>
        </div>
      </div>
      <TableComponent
        name="request-table"
        editComponent={
          <Popover placement="bottom" content={popupContent} trigger="click">
            <div className="SettingButtonWrapper">
              <ButtonDefault icon={<SettingsIcon />} />
            </div>
          </Popover>
        }
        history={history}
        ultimateLogout={ultimateLogout}
        refresh={refreshTimestamp}
        filteredDownload
        fullWidth
        withDictionary
        withTimeOutRefresh
        tablePending={dictionaryPending}
        badgeData={dictionaryStatus}
        scrollX={editColumns.length * 150}
        columnsData={editColumns}
        dataSource="request"
        fileDownloadName="Список заявок"
        saveSearch="requestSearch"
        onRow={({ id }) => {
          return {
            onClick: () => {
              history.push(`/request/preview/${id}`)
            },
          }
        }}
      />
      {statusModal.show && (
        <Modal
          status={statusModal.data.type}
          title="Смена статуса"
          close={closeModal}
          statusMessage="Смена статуса"
        >
          <div className="StatusModalMessageWrapper">
            {statusModal.data.message}
          </div>
          <div className="StatusModalButtonWrapper">
            <div style={{ paddingRight: `8px` }}>
              <ButtonPrimary
                onClick={() =>
                  changeStatus({
                    ...statusModal.data,
                    close: closeModal,
                    refresh: refreshList,
                    history,
                    ultimateLogout,
                  })
                }
              >
                Перевести
              </ButtonPrimary>
            </div>
            <ButtonDefault onClick={closeModal}>Отмена</ButtonDefault>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default Request

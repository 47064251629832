import React from "react"
import { Select, TreeSelect } from "antd"
import "./styles.css"

const SelectComponent = ({ children, placeholder, error = false, ...rest }) => (
  <div className={`SelectWrapper ${error ? `ErrorSelectWrapper` : ``}`}>
    <Select className="SelectComponent" placeholder={placeholder} {...rest}>
      {children}
    </Select>
    {!!error && <div className="ErrorText">{error}</div>}
  </div>
)

export const TreeSelectComponent = ({ error = false, ...rest }) => (
  <div className={error ? `ErrorSelectWrapper` : ``}>
    <TreeSelect className="TreeSelectComponentWrapper" {...rest} />
    {!!error && <div className="ErrorText">{error}</div>}
  </div>
)

export default SelectComponent

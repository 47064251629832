import axios from "axios"
import { ERROR_PAGE, SERVER_ERROR_PAGE } from "Container/BaseLayout/constants"

export const KAZ_DOMAIN = "sms.lotoclub.kz"
export const RUS_DOMAIN = "sms.betboom.ru"

const betRequest = ({
  method,
  url,
  requestBody,
  params,
  history,
  ultimateLogout,
}) => {
  const token = localStorage.getItem(`token`)
  localStorage.setItem(`404`, `hidden`)
  localStorage.setItem(`500`, `hidden`)

  const local = localStorage.getItem(`local`)

  if (
    window.location.hostname === KAZ_DOMAIN ||
    window.location.hostname === RUS_DOMAIN
  ) {
    switch (window.location.hostname) {
      case KAZ_DOMAIN:
        localStorage.setItem(`local`, `kz`)
        break
      case RUS_DOMAIN:
        localStorage.setItem(`local`, `ru`)
        break
      default:
        localStorage.setItem(`local`, `ru`)
    }
  }

  if (!local) {
    ultimateLogout(KAZ_DOMAIN)
    ultimateLogout(RUS_DOMAIN)
  }

  const isKz = local === `kz`

  return axios({
    method,
    url: `${process.env.REACT_APP_AUTH_URL}${isKz ? `/kz` : ``}/${url}`,
    data: requestBody,
    params,
    headers: {
      "Content-Type": `application/json`,
      token,
    },
  }).catch(error => {
    if (history && error.response) {
      if (error.response.status === 404) {
        localStorage.setItem(`404`, `show`)
        history.push(ERROR_PAGE)
      }
      if (error.response.status === 403) {
        ultimateLogout()
      }
      if (error.response.status === 500) {
        localStorage.setItem(`500`, `show`)
        history.push(SERVER_ERROR_PAGE)
      }
      throw error
    }
  })
}

export default betRequest

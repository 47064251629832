import React, { useState, useEffect } from "react"
import { Button, Popover } from "antd"
import "./styles.css"
import { getFirstAndLasteDayOfPreviousMonth } from "Container/Request/helper"
import { SENDED_SMS_TYPE } from "Container/Request"
import { useHistory } from "react-router-dom"
import { useDownload } from "api/hooks"
import SelectComponent from "../Select"
import { RangePickerComponent } from "../DatePicker"

export const ButtonPrimary = ({ children, disabled, ...rest }) => {
  return (
    <div className="ButtonPrimaryWrapper">
      <Button
        type="primary"
        disabled={disabled}
        className={disabled ? `ButtonPrimaryDisabled` : `ButtonPrimary`}
        {...rest}
      >
        {children}
      </Button>
    </div>
  )
}

export const ButtonDefault = ({ children, disabled, ...rest }) => {
  return (
    <div className="ButtonDefaultWrapper">
      <Button
        className={disabled ? `ButtonDefaultDisabled` : `ButtonDefault`}
        disabled={disabled}
        {...rest}
      >
        {children}
      </Button>
    </div>
  )
}

export const DownloadXlsButton = ({
  statuses,
  fileDownloadName,
  dataSource,
}) => {
  const history = useHistory()

  const [filterDownload, setFilterDownload] = useState({
    search: {
      status: SENDED_SMS_TYPE,
      send_after: getFirstAndLasteDayOfPreviousMonth(),
    },
  })

  const {
    loadPending: loadPendingFiltered,
    downloadFile: downloadFileFiltered,
  } = useDownload({
    history,
    dataSource,
    method: `POST`,
    fileName: fileDownloadName,
    requestBody: filterDownload,
  })

  const rangePickerDownloadOnChangeHandler = value => {
    setFilterDownload({
      ...filterDownload,
      search: {
        ...filterDownload.search,
        send_after: [value[0].valueOf(), value[1].valueOf()],
      },
    })
  }

  const [statusValue, setStatusValue] = useState(SENDED_SMS_TYPE)

  useEffect(() => {
    if (statusValue === 0) {
      setFilterDownload({
        search: { send_after: filterDownload.search.send_after },
      })
    } else {
      setFilterDownload({
        search: {
          status: statusValue,
          send_after: filterDownload.search.send_after,
        },
      })
    }
  }, [statusValue])

  const requestOptions = [
    { value: 0, label: <div className="DownloadFilteredOption">Все</div> },
    ...statuses.map(item => ({
      value: item.id,
      label: <div className="DownloadFilteredOption">{item.name}</div>,
    })),
  ]

  const selectDownloadOnChangeHandler = value => {
    setStatusValue(value)
  }

  const downloadContent = (
    <div className="XlsDownloadContent">
      <div>
        <div className="XlsDownloadContent_title">Даты рассылок</div>
        <RangePickerComponent
          value={filterDownload.search.send_after}
          onChange={rangePickerDownloadOnChangeHandler}
        />
      </div>
      <div>
        <div className="XlsDownloadContent_title">Статус рассылки</div>
        <div>
          <SelectComponent
            placeholder="Статус рассылки"
            options={requestOptions}
            value={statusValue}
            onChange={selectDownloadOnChangeHandler}
          />
        </div>
      </div>
      <ButtonPrimary
        className="CreateRequestButton"
        disabled={loadPendingFiltered}
        loading={loadPendingFiltered}
        onClick={downloadFileFiltered}
      >
        Выгрузить
      </ButtonPrimary>
    </div>
  )

  return (
    <div className="XlsDownloadBtn">
      <Popover
        overlayClassName="XlsDownloadBtnPopover"
        placement="bottom"
        trigger="click"
        content={downloadContent}
      >
        <div className="">
          <ButtonDefault>Выгрузить</ButtonDefault>
        </div>
      </Popover>
    </div>
  )
}

export default ButtonDefault

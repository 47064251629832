import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Input } from "antd"
import { ReactComponent as BetLogoIcon } from "Container/icons/bb-logo.svg"
import { ReactComponent as LotoClubLogoIcon } from "Container/icons/loto-club-logo.svg"
import ButtonDefault from "Components/Button"
import { login } from "./api"
import "./style.css"
import { KAZ_DOMAIN, RUS_DOMAIN } from "../../api/request"

const LoginPage = ({ isAuth, setAuth, currentLocal, setCurrentLocal }) => {
  const [loginData, setLoginData] = useState({
    username: ``,
    password: ``,
    errors: false,
    pending: false,
  })
  const isKzDomen = window.location.origin === `https://${KAZ_DOMAIN}`
  const isRuDomen = window.location.origin === `https://${RUS_DOMAIN}`
  const history = useHistory()
  const isKz = currentLocal === `kz`
  const loginAction = () => {
    setLoginData({ ...loginData, pending: true })
    login({
      setAuth,
      loginData,
      setLoginData,
      isKz,
    })
  }

  useEffect(() => {
    if (isAuth && isAuth.token) {
      history.push(`/request`)
    }
  }, [isAuth])

  useEffect(() => {
    if (!localStorage.getItem("local")) {
      const hasClassKz =
        document.getElementsByClassName("LocalActiveKz").length > 0
      if (hasClassKz) {
        localStorage.setItem(`local`, `kz`)
        setCurrentLocal(`kz`)
      } else {
        localStorage.setItem(`local`, `ru`)
        setCurrentLocal(`ru`)
      }
    }
  }, [])
  return (
    <div className={isKz ? `LoginWrapper LoginWrapper-kz` : `LoginWrapper`}>
      <div className="LoginWrapperIcon">
        {isKz ? <LotoClubLogoIcon /> : <BetLogoIcon />}
      </div>
      <div className="LoginFormWrapper">
        <div className="LoginFormLabel">Сервис СМС рассылок</div>
        <div
          className="LoginFormFieldWrapper"
          style={{ paddingBottom: !loginData.errors ? `32px` : `` }}
        >
          <div className="LoginFormField">
            <Input
              placeholder="Логин"
              value={loginData.username}
              style={{ border: loginData.errors ? `1px solid #ff0025` : `` }}
              onChange={({ target: { value } }) =>
                setLoginData({ ...loginData, username: value, errors: false })
              }
              onPressEnter={loginAction}
            />
          </div>
          <div className="LoginFormField">
            <Input.Password
              placeholder="Пароль"
              value={loginData.password}
              style={{ border: loginData.errors ? `1px solid #ff0025` : `` }}
              onChange={({ target: { value } }) =>
                setLoginData({ ...loginData, password: value, errors: false })
              }
              onPressEnter={loginAction}
            />
          </div>
        </div>
        {loginData.errors && (
          <div className="ErrorMessage">Ошибка авторизации</div>
        )}
        <div className="LoginFormButtons">
          <div style={{ display: "flex" }}>
            <ButtonDefault
              loading={loginData.pending}
              className="LoginFormEnter"
              onClick={loginAction}
            >
              Войти
            </ButtonDefault>
            {!isKzDomen && !isRuDomen && (
              <div className="LocalSwitcher">
                <span
                  className={!isKz ? `LocalActiveRu` : ``}
                  onClick={() => {
                    localStorage.setItem(`local`, `ru`)
                    setCurrentLocal(`ru`)
                  }}
                >
                  ru
                </span>
                <span
                  className={isKz ? `LocalActiveKz` : ``}
                  onClick={() => {
                    localStorage.setItem(`local`, `kz`)
                    setCurrentLocal(`kz`)
                  }}
                >
                  kz
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPage

import betRequest from "api/request"
import { notification } from "antd"

/**
 * Ищет в тексте хотя бы одно вхождение любой строки из массива строк
 *
 * @param text Текст
 * @param arrayOfNeedles Массив строк, которые надо найти
 * @returns boolean
 */
export const hasStopWords = (text, arrayOfNeedles = [], isKz = false) => {
  if (isKz) return false
  const lowerCaseText = text
    .toString()
    .trim()
    .toLowerCase()
  return arrayOfNeedles.some(element => {
    return lowerCaseText.indexOf(element.trim().toLowerCase()) !== -1
  })
}

/**
 * Ищет в массиве строк хотя бы одно вхождение любой строки из массива строк
 *
 * @param textArray Массив строк
 * @param arrayOfNeedles Массив строк, которые надо найти
 * @returns boolean
 */
export const hasStopWordsInArray = (textArray, arrayOfNeedles) => {
  return textArray.some(element => {
    return hasStopWords(element, arrayOfNeedles)
  })
}

export const getFirstAndLasteDayOfPreviousMonth = () => {
  const date = new Date()
  const endDate = date.setDate(0)
  const startDate = date.setDate(1)
  return [startDate, endDate]
}

const changeStatus = ({
  id,
  newStatusId,
  refresh,
  close,
  history,
  ultimateLogout,
}) => {
  betRequest({
    method: `post`,
    url: `request/change_status`,
    requestBody: { id, status: newStatusId },
    history,
    ultimateLogout,
  })
    .then(() => {
      notification.success({
        message: "Статус успешно изменен",
      })
      refresh()
      close()
    })
    .catch(() => {
      notification.error({
        message: "Ошибка смены статуса",
      })
      close()
    })
}

export default changeStatus

import React, { useEffect, useRef } from "react"

const NumbersList = ({ pending, numbers, total, scrollRequest }) => {
  const numbersString = (numbers || []).join(`, `)
  const listRef = useRef()
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const element = listRef.current
    const scrollHandle = () => {
      const check =
        element.scrollHeight - element.scrollTop === element.clientHeight
      if (check && numbers) {
        scrollRequest()
      }
    }
    const clear = () => element.removeEventListener(`scroll`, scrollHandle)
    if (element) {
      if (numbers.length < total) {
        element.addEventListener(`scroll`, scrollHandle, false)
      } else {
        clear()
      }
      return () => {
        clear()
      }
    }
  }, [numbers])
  return (
    <div
      className={`NumbersWrapper ${pending ? `DisabledNumbersWrapper` : ``}`}
      ref={listRef}
    >
      {numbersString}
    </div>
  )
}

export default NumbersList

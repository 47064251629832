import React, { useState } from "react"
import Modal from "Components/Modal"
import moment from "moment"
import get from "lodash/get"
import isArray from "lodash/isArray"
import { useHistory } from "react-router-dom"
import ButtonDefault, { ButtonPrimary } from "Components/Button"
import { REQUEST_ROUTE } from "Container/BaseLayout/constants"
import { periodOptionsRu, periodOptionsKz } from "Container/Request/Form"
import sendRequest from "./helper"
import "./styles.css"

const dateValue = (value, withTime) =>
  value
    ? moment.utc(value).format(`YYYY-MM-DD ${withTime ? `HH:mm` : ``}`)
    : `-`
const getFourChoiceValue = (value, variables) => {
  switch (value) {
    case 0:
      return variables[0]
    case 1:
      return variables[1]
    case 2:
    case 3:
      return variables[value].text
        ? `${variables[value].text}${(
            get(variables, `[${value}].value`) || []
          ).join(`, `)}`
        : variables[value]
    default:
      return variables[0]
  }
}

const getFromTo = ({ from = `-`, to = `-`, withCurrency }) =>
  `от ${from || `-`} ${withCurrency && from ? `₽` : ``} до ${to || `-`} ${
    withCurrency && to ? `₽` : ``
  }`
const getFromDate = ({ from = `-`, to = `-` }) =>
  `с ${dateValue(from)} по ${dateValue(to)}`

const getPeriod = ({ from = `-`, to = `-`, period }) => {
  const isKz = localStorage.getItem(`local`) === `kz`
  if (from && to) {
    return `${dateValue(from)} - ${dateValue(to)}`
  }
  const currentPeriod = (isKz ? periodOptionsKz : periodOptionsRu).filter(
    item => item.value === period,
  )
  return currentPeriod ? currentPeriod[0].label : ``
}

const RequestPreview = ({
  id,
  isNew,
  isCopy,
  close,
  data,
  dictionaryData,
  template,
  ultimateLogout,
  viewOnly,
  double,
}) => {
  const [modalStatus, setModalStatus] = useState(null)
  const [sendPending, setSendPending] = useState(false)
  const history = useHistory()
  const successText = isNew || isCopy ? `создана` : `сохранена`
  const isKz = localStorage.getItem(`local`) === `kz`
  const {
    games,
    gateway,
    subject_type: subjectTypeData,
    guest_count: guestCount,
  } = dictionaryData

  const subjectType =
    (subjectTypeData &&
      subjectTypeData.find(item => item.id === data.sms.subject_type)) ||
    `-`
  const gatewayData =
    (gateway && gateway.find(item => item.id === data.sms.gateway)) || `-`

  const guestCountData =
    (guestCount &&
      guestCount.find(item => item.id === data.filters.guest_count)) ||
    `-`

  const haveConfinesPeriod =
    data.filters.confines_period && data.filters.confines_period.type === 2

  const stupidAlert = data.filters.confines === 0 || data.filters.confines === 3
  return (
    <>
      {!modalStatus ? (
        <Modal
          width="784px"
          close={close}
          title={
            viewOnly ? `Данные фильтра` : `Подтвердите отправляемые данные`
          }
        >
          <div style={{ paddingTop: `16px` }}>
            {!!double?.length && (
              <div className="PlateWrapper">
                <div className="PlateHeader PlateHeader_Warning">
                  Внимание! Дублирование клуба:
                </div>
                <div className="PlateWrapper_WarningContent">
                  {double.map(item => {
                    return (
                      <div className="PlateWrapper_Warning">
                        <div>Клуб: {item.club}</div>
                        <div>
                          <span>
                            Рассылки:
                            {item.requests.map(el => el.request_id).join(", ")}
                          </span>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
            <div className="PlateWrapper">
              <div className="PlateHeader">Рассылка</div>
              <div className="PlateColumnWrapper">
                <div className="PlateColumn">
                  <div className="PlateColumnCeil">
                    <div className="PlateLabel">НАЗВАНИЕ ФИЛЬТРА</div>
                    <div>{template && template.name ? template.name : `-`}</div>
                  </div>
                  <div className="PlateColumnCeil">
                    <div className="PlateLabel">ТЕМА РАССЫЛКИ</div>
                    <div>{subjectType.name || `-`}</div>
                  </div>
                  {!isKz && (
                    <div className="PlateColumnCeil">
                      <div className="PlateLabel">НОМЕР ТИКЕТА</div>
                      <div>{data.sms.ticket_id}</div>
                    </div>
                  )}
                </div>
                <div className="PlateColumn">
                  <div className="PlateColumnCeil">
                    <div className="PlateLabel">ДАТА ОТПРАВЛЕНИЯ</div>
                    <div>
                      {moment(data.sms.dttm_to_send).format(`YYYY-MM-DD HH:mm`)}
                    </div>
                  </div>
                  <div className="PlateColumnCeil">
                    <div className="PlateLabel">ШЛЮЗ</div>
                    <div>{gatewayData.name || `-`}</div>
                  </div>
                  <div className="PlateColumnCeil">
                    <div className="PlateLabel">ТЕКСТ СМС</div>
                    <div>{data.sms.text}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="PlateWrapper">
              <div className="PlateHeader">Ограничения</div>
              <div className="PlateColumnWrapper">
                <div className="PlateColumn">
                  <div className="PlateColumnCeil">
                    <div className="PlateLabel">ОПЛАТА ЗА СЧЕТ</div>
                    <div>{data.filters.payment ? `Партнера` : `Компании`}</div>
                  </div>
                  <div className="PlateColumnCeil">
                    <div className="PlateLabel">ОГРАНИЧЕНИЯ</div>
                    <div className={stupidAlert ? `PlateLabelAlert` : ``}>
                      {getFourChoiceValue(data.filters.confines, [
                        `Вся сеть РФ`,
                        `Список номеров`,
                        {
                          text: `Номера клуба: `,
                          value: isArray(data.filters.halls)
                            ? data.filters.halls
                            : [data.filters.halls],
                        },
                        {
                          text: `Вся сеть РФ, исключая клубы: `,
                          value: isArray(data.filters.halls)
                            ? data.filters.halls
                            : [data.filters.halls],
                        },
                      ])}
                    </div>
                  </div>
                  <div className="PlateColumnCeil">
                    <div className="PlateLabel">ТИП РАССЫЛКИ</div>
                    <div>
                      {data.filters.type_request ? `На один клуб` : `Групповая`}
                    </div>
                  </div>
                </div>
                <div className="PlateColumn">
                  {!haveConfinesPeriod && (
                    <div className="PlateColumnCeil">
                      <div className="PlateLabel">ЗА ПЕРИОД</div>
                      <div>{getPeriod(data.filters.confines_period)}</div>
                    </div>
                  )}
                  {!(
                    data.filters.type_phones === 2 && !data.filters.phones
                  ) && (
                    <div className="PlateColumnCeil">
                      <div className="PlateLabel">РАЗОСЛАТЬ ПО</div>
                      <div>
                        {getFourChoiceValue(data.filters.type_phones, [
                          `Всем номерам клуба`,
                          `Только по номерам`,
                          {
                            text: `По всем номерам кроме: `,
                            value: data.filters.phones,
                          },
                        ])}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="PlateWrapper">
              <div className="PlateHeader">Аудитория</div>
              <div className="SecondHeader">Игры</div>
              {data.filters.type_game.game.map((item, key) => {
                const gamesData = []
                const currentGames =
                  data.filters.type_game.game[key].games || []
                games.map(gameItem => {
                  if (currentGames.includes(gameItem.id)) {
                    gamesData.push(gameItem.name)
                  }
                  return false
                })
                return (
                  <div>
                    <div
                      key={`${item.length}_${item.dttm_from}`}
                      className="RowWrapper"
                    >
                      <div className="PlateColumn">
                        <div className="PlateColumnCeil">
                          <div className="PlateLabel">УЧИТЫВАТЬ СТАВКИ ПО</div>
                          <div>
                            {data.filters.type_filter_bets
                              ? `Кошельку`
                              : `Телефону`}
                          </div>
                        </div>
                        <div className="PlateColumnCeil">
                          <div className="PlateLabel">РАЗДЕЛ ИГРЫ</div>
                          <div>{gamesData.join(`, `)}</div>
                        </div>
                        <div className="PlateColumnCeil">
                          <div className="PlateLabel">
                            ДАТА ПОСЛЕДНЕЙ СТАВКИ
                          </div>
                          <div>
                            {dateValue(data.filters.type_game.game[key].dttm)}
                          </div>
                        </div>
                        <div className="PlateColumnCeil">
                          <div className="PlateLabel">
                            ПРОЦЕНТ ОТ СТАВОК БОЛЕЕ
                          </div>
                          <div>
                            {getFourChoiceValue(
                              data.filters.type_game.game[key].percentage_bets,
                              [`не учитывать`, `10%`, `20%`],
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="PlateColumn">
                        <div className="PlateColumnCeil">
                          <div className="PlateLabel">СУММА ОТ СТАВОК</div>
                          <div>
                            {getFromTo({
                              from: data.filters.type_game.game[key].sum_from,
                              to: data.filters.type_game.game[key].sum_to,
                            })}
                          </div>
                        </div>
                        <div className="PlateColumnCeil">
                          <div className="PlateLabel">ПЕРИОД СТАВКИ</div>
                          <div>
                            {data.filters.type_game.game[key].dttm_all
                              ? `За весь период`
                              : getFromDate({
                                  from:
                                    data.filters.type_game.game[key].dttm_from,
                                  to: data.filters.type_game.game[key].dttm_to,
                                })}
                          </div>
                        </div>
                        <div className="PlateColumnCeil">
                          <div className="PlateLabel">БАЛАНС</div>
                          <div>
                            {getFourChoiceValue(
                              data.filters.type_game.game[key].balance,
                              [`Денежный`, `Бонусный`, `Общий`],
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Rectangle" />
                  </div>
                )
              })}
              {!isKz && (
                <div>
                  <div className="SecondHeader">Спорт</div>
                  <div>
                    <div className="RowWrapper">
                      <div className="PlateColumn">
                        <div className="PlateColumnCeil">
                          <div className="PlateLabel">
                            ДАТА ПОСЛЕДНЕЙ СТАВКИ
                          </div>
                          <div>
                            {dateValue(data.filters.type_game.sport.dttm)}
                          </div>
                        </div>
                        <div className="PlateColumnCeil">
                          <div className="PlateLabel">
                            ПРОЦЕНТ ОТ СТАВОК БОЛЕЕ
                          </div>
                          <div>
                            {getFourChoiceValue(
                              data.filters.type_game.sport.percentage_bets,
                              [`не учитывать`, `10%`, `20%`],
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="PlateColumn">
                        <div className="PlateColumnCeil">
                          <div className="PlateLabel">СУММА ОТ СТАВОК</div>
                          <div>
                            {getFromTo({
                              from: data.filters.type_game.sport.sum_from,
                              to: data.filters.type_game.sport.sum_to,
                              withCurrency: true,
                            })}
                          </div>
                        </div>
                        <div className="PlateColumnCeil">
                          <div className="PlateLabel">ПЕРИОД СТАВКИ</div>
                          <div>
                            {data.filters.type_game.sport.dttm_all
                              ? `За весь период`
                              : getFromDate({
                                  from: data.filters.type_game.sport.dttm_from,
                                  to: data.filters.type_game.sport.dttm_to,
                                })}
                          </div>
                        </div>
                        <div className="PlateColumnCeil">
                          <div className="PlateLabel">БАЛАНС</div>
                          <div>
                            {data.filters.type_game.sport.balance
                              ? `Фрибетный баланс**`
                              : `Денежный`}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Rectangle" />
                  </div>
                </div>
              )}
              <div className="RowWrapper">
                <div className="PlateColumn">
                  {!isKz && (
                    <div className="PlateColumnCeil">
                      <div className="PlateLabel">КОЛИЧЕСТВО ГОСТЕЙ</div>
                      <div>{guestCountData.name || `-`}</div>
                    </div>
                  )}
                  <div className="PlateColumnCeil">
                    <div className="PlateLabel">БОНУСНЫЙ БАЛАНС</div>
                    <div>
                      {getFromTo({
                        from: data.filters.bonus_balance.from,
                        to: data.filters.bonus_balance.to,
                      })}
                    </div>
                  </div>
                  <div className="PlateColumnCeil">
                    <div className="PlateLabel">ДЕНЕЖНЫЙ БАЛАНС</div>
                    <div>
                      {getFromTo({
                        from: data.filters.money_balance.from,
                        to: data.filters.money_balance.to,
                      })}
                    </div>
                  </div>
                </div>

                <div className="PlateColumn">
                  {!isKz && (
                    <div className="PlateColumnCeil">
                      <div className="PlateLabel">WIN КЛУБА</div>
                      <div>
                        {getFromTo({
                          from: data.filters.win.sum_from,
                          to: data.filters.win.to,
                        })}
                      </div>
                      <div>
                        {data.filters.win.dttm_all
                          ? `За весь период`
                          : getFromDate({
                              from: data.filters.win.dttm_from,
                              to: data.filters.win.dttm_to,
                            })}
                      </div>
                    </div>
                  )}
                  {!isKz && (
                    <div className="PlateColumnCeil">
                      <div className="PlateLabel">СУММА ДЕПОЗИТОВ</div>
                      <div>
                        {getFromTo({
                          from: data.filters.deposit.sum_from,
                          to: data.filters.deposit.sum_to,
                        })}
                      </div>
                      <div>
                        {data.filters.deposit.dttm_all
                          ? `За весь период`
                          : getFromDate({
                              from: data.filters.deposit.dttm_from,
                              to: data.filters.deposit.dttm_to,
                            })}
                      </div>
                    </div>
                  )}
                  {!isKz && (
                    <div className="PlateColumnCeil">
                      <div className="PlateLabel">КОЛИЧЕСТВО ВИЗИТОВ</div>
                      <div>
                        {getFromTo({
                          from: data.filters.visits.from,
                          to: data.filters.visits.to,
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {!viewOnly && (
              <div className="PreviewButtonWrapper RowWrapper">
                <div style={{ paddingRight: `16px` }}>
                  <ButtonPrimary
                    onClick={() =>
                      sendRequest({
                        isNew,
                        isCopy,
                        id,
                        data,
                        setModalStatus,
                        setSendPending,
                        history,
                        ultimateLogout,
                        templateId: template ? template.id : undefined,
                      })
                    }
                    disabled={sendPending}
                    loading={sendPending}
                  >
                    {isNew || isCopy ? `Отправить` : `Сохранить`}
                  </ButtonPrimary>
                </div>
                <ButtonDefault disabled={sendPending} onClick={close}>
                  Отмена
                </ButtonDefault>
              </div>
            )}
          </div>
        </Modal>
      ) : (
        <Modal
          width={`${modalStatus ? 512 : 784}px`}
          close={close}
          status={modalStatus}
          statusMessage={
            modalStatus === `complete`
              ? `Заявка успешно ${successText}`
              : `Ошибка`
          }
        >
          <>
            <div style={{ paddingBottom: `28px` }}>
              {modalStatus === `error`
                ? `Что-то пошло не так, попробуйте еще раз`
                : ``}
            </div>
            <ButtonPrimary
              onClick={
                modalStatus === `error`
                  ? close
                  : () => history.push(REQUEST_ROUTE)
              }
            >
              {modalStatus === `error` ? `Закрыть` : `На главную`}
            </ButtonPrimary>
          </>
        </Modal>
      )}
    </>
  )
}

export default RequestPreview

import React, { useEffect, useState } from "react"
import InputComponent from "Components/Input"
import { Spin } from "antd"
import ButtonDefault, { ButtonPrimary } from "Components/Button"
import { ReactComponent as DeleteIcon } from "Container/icons/blue_delete.svg"
import { ReactComponent as SaveIcon } from "Container/icons/save.svg"
import { MenuOutlined, CopyOutlined, LoadingOutlined } from "@ant-design/icons"
import Modal from "Components/Modal"

import TemplateForm from "./TemplateForm"
import RequestPreview from "./RequestPreview"
import NumbersList from "./NumbersList"

import {
  saveTemplate,
  validateRequest,
  useRequestPreviewNumbers,
  makeDataLocaleAgain,
} from "./helper"
import "./styles.css"

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const RightPanel = ({
  resetFilter,
  data: filterData,
  id,
  isNew,
  isCopy,
  ultimateLogout,
  history,
  setValidate,
  notValid,
  showNumbers,
  toggleNumbers,
  template,
  setTemplate,
  dictionaryData,
  isValidStopWords,
  needValidateStopWords,
}) => {
  const dateFixData = makeDataLocaleAgain(filterData)

  const isKz = localStorage.getItem(`local`) === `kz`

  const saveButtonDisabled =
    isKz &&
    filterData &&
    filterData.filters &&
    !filterData.filters.platform_ukm &&
    !filterData.filters.platform_clubs

  const [showSaveModal, toggleSaveModal] = useState(false)
  const [showPreviewModal, togglePreviewModal] = useState(false)
  const [double, setDouble] = useState([])

  const {
    numbersData,
    getNumbers,
    makeRequest,
    clearNumbers,
  } = useRequestPreviewNumbers({
    data: dateFixData,
    history,
    ultimateLogout,
    setValidate,
    showNumbers,
    openModal: () => toggleNumbers(!showNumbers),
  })

  useEffect(() => {
    if (!showNumbers) {
      clearNumbers()
    }
  }, [showNumbers])

  const { data, search, page, pending, total } = numbersData

  return (
    <div className="RightPanelWrapper">
      <div className="Title">Итого</div>
      <div className="Label">ШАБЛОН:</div>
      <div className="TemplateWrapper">
        <div className="Value">{template.name || `-`}</div>
        <ButtonDefault
          onClick={() => {
            validateRequest({
              data: dateFixData,
              history,
              ultimateLogout,
              setValidate,
              setDouble,
              openModal: () => {
                setTemplate({ ...template, status: null, statusMessage: null })
                toggleSaveModal(!showSaveModal)
              },
              id,
            })
          }}
          disabled={notValid}
          className="Button"
          icon={<SaveIcon />}
          size="small"
        />
      </div>
      <div className="Rectangle" />
      {pending ? (
        <Spin indicator={antIcon} />
      ) : (
        <div
          className={`LinkWrapper ${notValid ? `LinkDisabled` : ``}`}
          onClick={() => {
            if (!notValid) {
              getNumbers({ ...numbersData, search: null }, 0)
            }
            if (showNumbers) {
              toggleNumbers(false)
            }
          }}
        >
          <div style={{ paddingRight: `4px` }}>
            <MenuOutlined />
          </div>
          {!showNumbers ? `Показать` : `Скрыть`} список номеров
        </div>
      )}
      {showNumbers ? (
        <div className="PreviewNumbersWrapper">
          <div className="Label">КОЛИЧЕСТВО СМС:</div>
          <div className="Value">{data.sms_count}</div>
          <div className="Label">КОЛИЧЕСТВО НОМЕРОВ:</div>
          <div className="Value">{data.numbers_count}</div>
          <div className="Label">СТОИМОСТЬ РАССЫЛКИ:</div>
          <div className="Value">
            {data.price * data.numbers_count * data.sms_count}
          </div>
          <div className="Rectangle" />
          <NumbersList
            numbers={data.numbers}
            pending={pending}
            total={total}
            scrollRequest={() =>
              getNumbers({ ...numbersData, page: page + 1 }, 0)
            }
          />
          <div className="SearchWrapper">
            <InputComponent
              className="SearchInput"
              size="small"
              value={search}
              onChange={({ target: { value } }) => {
                getNumbers({ ...numbersData, search: value }, 1000)
              }}
            />
            <ButtonDefault
              onClick={() => {
                makeRequest({ ...numbersData, page: 1, perPage: 0 }, 0).then(
                  ({ data: { numbers } }) => {
                    if (numbers) {
                      const stringNumbers = (numbers || []).join(`, `)
                      navigator.clipboard.writeText(stringNumbers)
                    }
                  },
                )
              }}
              className="Button"
              icon={<CopyOutlined />}
              size="small"
            />
          </div>
        </div>
      ) : (
        <div className="Rectangle" />
      )}
      <div
        onClick={() => {
          resetFilter()
          toggleNumbers(false)
        }}
        className="LinkWrapper"
        style={{ paddingBottom: `16px` }}
      >
        <div style={{ marginTop: `-2px`, marginRight: `-12px` }}>
          <DeleteIcon />
        </div>
        Очистить все фильтры
      </div>
      <ButtonPrimary
        onClick={() =>
          validateRequest({
            data: dateFixData,
            history,
            ultimateLogout,
            setValidate,
            setDouble,
            isNew,
            isCopy,
            id,
            openModal: () => togglePreviewModal(!showPreviewModal),
          })
        }
        disabled={!isValidStopWords || saveButtonDisabled}
        type="primary"
        style={{ width: `100%` }}
        size="large"
      >
        {isNew || isCopy ? `Отправить` : `Сохранить`}
      </ButtonPrimary>
      {!isKz && !isValidStopWords && !needValidateStopWords && (
        <div
          className="DescriptionWrapper"
          style={{ textAlign: `center`, color: `#e80012` }}
        >
          Недопустимая формулировка
        </div>
      )}
      {saveButtonDisabled && (
        <div
          className="DescriptionWrapper"
          style={{ textAlign: `center`, color: `#e80012` }}
        >
          Необходимо выбрать платформу, по которой будет произведена рассылка.
        </div>
      )}
      {showSaveModal && (
        <Modal
          title="Сохранить шаблон"
          close={() => toggleSaveModal(false)}
          status={template.status}
          statusMessage={template.statusMessage}
          width="512px"
        >
          <TemplateForm
            close={() => toggleSaveModal(false)}
            repeat={() =>
              setTemplate({ ...template, status: null, statusMessage: null })
            }
            save={name =>
              saveTemplate({
                name,
                setTemplate,
                data: dateFixData,
                history,
                ultimateLogout,
                setValidate,
              })
            }
            template={template}
            data={dateFixData}
          />
        </Modal>
      )}
      {showPreviewModal && (
        <RequestPreview
          close={() => togglePreviewModal(!showPreviewModal)}
          dictionaryData={dictionaryData}
          data={dateFixData}
          double={double}
          ultimateLogout={ultimateLogout}
          template={template}
          id={id}
          isNew={isNew}
          isCopy={isCopy}
        />
      )}
    </div>
  )
}

export default RightPanel

export const REQUEST_ROUTE = `/request`
export const BLACK_LIST_ROUTE = `/black-list`
export const REQUEST_ROUTE_EDIT = `/request/:id`
export const REQUEST_ROUTE_COPY = `/request/copy/:id`
export const REQUEST_ROUTE_PREVIEW = `/request/preview/:id`
export const FILTER_STATISTIC_ROUTE = `/filter-statistic`
export const LOGIN_ROUTE = `/login`
export const ERROR_PAGE = `/404`
export const SERVER_ERROR_PAGE = `/500`
export const REQUEST_ROUTE_INFOBIP_GET_CALLBACK = `/infobip/getcallback/:request_id/:package_id`

export const isActive = (current, locationPath) => current === locationPath

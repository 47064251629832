import React from "react"
import { Space, Select } from "antd"
import { RangePickerComponent } from "Components/DatePicker"
import SelectComponent from "Components/Select"
import InputComponent from "Components/Input"
import ButtonDefault, { ButtonPrimary } from "Components/Button"
import { SearchOutlined } from "@ant-design/icons"
import omit from "lodash/omit"
import "./styles.css"

const { Option } = Select

/**
 * Add to current column search component with all actions
 * @param {string} name - column title/key
 * @param { type } - column type, date for rangepicker or input for default
 * @param {object} search - search data for useTable
 * @param {function} setSearch - action for changing search data
 * @param {function} confirm - native ant table action for closing search window
 */

const StatusSearchComponent = ({
  badgeData = [],
  localSearch,
  setLocalSearch,
}) => {
  return (
    <div style={{ paddingBottom: `8px` }}>
      <SelectComponent
        name="status"
        disabled={!badgeData}
        value={localSearch.status}
        style={{ width: `100%` }}
        onChange={value => setLocalSearch({ ...localSearch, status: value })}
        placeholder="Выберите статус"
      >
        {badgeData.map(item => (
          <Option value={item.id} key={`${item.id}_${item.name.length}`}>
            {item.name}
          </Option>
        ))}
      </SelectComponent>
    </div>
  )
}

const SearchComponent = ({
  name,
  type,
  search,
  setSearch,
  localSearch,
  setLocalSearch,
  confirm,
  badgeData,
  tableData,
  setTableData,
}) => {
  return (
    <div style={{ padding: 8 }}>
      {!!(type && type === `date`) && (
        <RangePickerComponent
          value={localSearch[name] || [null, null]}
          style={{ marginBottom: 8 }}
          onChange={value =>
            setLocalSearch({
              ...localSearch,
              [name]: value
                ? [
                    value[0].startOf("day").valueOf(),
                    value[1].endOf("day").valueOf(),
                  ]
                : null,
            })
          }
        />
      )}
      {!!(type && type === `badge`) && (
        <StatusSearchComponent
          badgeData={badgeData}
          localSearch={localSearch}
          setLocalSearch={setLocalSearch}
        />
      )}
      {!!(type !== `badge` && type !== `date`) && (
        <InputComponent
          placeholder="Поиск"
          value={localSearch[name]}
          onChange={({ target: { value } }) =>
            setLocalSearch({ ...localSearch, [name]: value })
          }
          onPressEnter={() => {
            setSearch({ ...search, ...localSearch })
            confirm()
          }}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
      )}
      <Space>
        <ButtonPrimary
          className="SearchButtons"
          onClick={() => {
            if (
              type === `date` &&
              !!localSearch[name] &&
              !!localSearch[name][0]
            ) {
              const date = new Date()
              const timeOffset = date.getTimezoneOffset() * 60000
              const utcSearch = [
                localSearch[name][0] - timeOffset,
                localSearch[name][1] - timeOffset,
              ]
              setSearch({ ...search, ...localSearch, [name]: utcSearch })
            } else setSearch({ ...search, ...localSearch })
            setTableData({ ...tableData, page: 1 })
            confirm()
          }}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Поиск
        </ButtonPrimary>
        <ButtonDefault
          className="ResetButton"
          onClick={() => {
            setSearch(omit(search, `${name}`))
            setLocalSearch(omit(search, `${name}`))
            confirm()
          }}
          size="small"
          style={{ width: 90 }}
        >
          Сбросить
        </ButtonDefault>
      </Space>
    </div>
  )
}

const getColumnSearchProps = searchData => ({
  filterDropdown: ({ confirm }) => {
    const {
      name,
      type,
      search,
      setSearch,
      badgeData,
      localSearch,
      setLocalSearch,
      tableData,
      setTableData,
    } = searchData
    return (
      <SearchComponent
        name={name}
        type={type}
        search={search}
        setSearch={setSearch}
        confirm={confirm}
        badgeData={badgeData}
        localSearch={localSearch}
        setLocalSearch={setLocalSearch}
        tableData={tableData}
        setTableData={setTableData}
      />
    )
  },
  filterIcon: () => {
    const { name, search } = searchData
    const filtered = Boolean(search[name])
    return (
      <SearchOutlined
        className={filtered ? `TableSearchIconActive` : `TableSearchIcon`}
      />
    )
  },
})

export default getColumnSearchProps

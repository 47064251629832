import { useState } from "react"
import betRequest from "api/request"
import omit from "lodash/omit"
import { notification } from "antd"

let waitNumbers

// eslint-disable-next-line import/prefer-default-export
export const saveTemplate = ({
  name,
  setTemplate,
  data,
  history,
  ultimateLogout,
}) => {
  const username = localStorage.getItem(`username`)
  const date = new Date()
  const timeOffset = date.getTimezoneOffset() * 60000
  const isKz = localStorage.getItem(`local`) === `kz`
  const smsData = {
    ...data.sms,
    dttm_to_send: data.sms.dttm_to_send
      ? data.sms.dttm_to_send - timeOffset
      : null,
  }
  const requestBody = {
    filter: {
      filters: isKz
        ? omit(
            {
              ...data.filters,
              type_game: { game: data.filters.type_game.game },
            },
            [
              `payment`,
              `win`,
              `deposit`,
              `visits`,
              `top`,
              `top_bet`,
              `guest_count`,
              `ticket_id`,
            ],
          )
        : data.filters,
      sms: omit(smsData, isKz ? `ticket_id` : ``),
    },
    name,
    username,
  }
  betRequest({
    url: `filter/template/add`,
    method: `post`,
    history,
    ultimateLogout,
    requestBody,
  })
    .then(() => {
      setTemplate({
        name,
        status: `complete`,
        statusMessage: `Шаблон успешно сохранен`,
        data,
      })
    })
    .catch(() =>
      setTemplate({
        name: null,
        status: `error`,
        statusMessage: `Ошибка`,
        data,
      }),
    )
}

export const validateRequest = ({
  data,
  history,
  setValidate,
  openModal,
  setDouble,
  ultimateLogout,
  isNew,
  isCopy,
  id,
}) => {
  const username = localStorage.getItem(`username`)
  // const date = new Date()
  // const timeOffset = date.getTimezoneOffset() * 60000
  const isKz = localStorage.getItem(`local`) === `kz`
  const smsData = {
    ...data.sms,
    // dttm_to_send: data.sms.dttm_to_send
    //   ? data.sms.dttm_to_send - timeOffset
    //   : null,
  }

  const requestBody = {
    filter: {
      filters: isKz
        ? omit(
            {
              ...data.filters,
              type_game: { game: data.filters.type_game.game },
            },
            [
              `win`,
              `payment`,
              `deposit`,
              `visits`,
              `top`,
              `top_bet`,
              `guest_count`,
              `ticket_id`,
            ],
          )
        : data.filters,
      sms: omit(smsData, isKz ? `ticket_id` : ``),
    },
  }

  betRequest({
    method: `post`,
    url: `request/validate`,
    requestBody: isNew || isCopy ? requestBody : { ...requestBody, id },
    history,
    ultimateLogout,
    username,
  })
    .then(response => {
      if (response.data.double !== undefined) {
        setDouble(response.data.double)
      }
      openModal()
    })
    .catch(error => {
      notification.error({
        message: "Ошибка валидации",
      })
      if (error && error.response && error.response.data) {
        setValidate(error.response.data)
      }
    })
}

const initialNumbers = {
  data: {
    numbers: [],
  },
  pending: false,
  perPage: 50,
  page: 1,
  search: null,
  total: 0,
}

export const useRequestPreviewNumbers = ({
  data,
  history,
  ultimateLogout,
  showNumbers,
  setValidate,
  openModal,
}) => {
  const [requestParams, setRequestParams] = useState(initialNumbers)

  const username = localStorage.getItem(`username`)
  const date = new Date()
  const timeOffset = date.getTimezoneOffset() * 60000

  const isKz = localStorage.getItem(`local`) === `kz`

  const smsData = {
    ...data.sms,
    dttm_to_send: data.sms.dttm_to_send
      ? data.sms.dttm_to_send - timeOffset
      : null,
  }

  const makeRequest = params => {
    return betRequest({
      method: `post`,
      url: `request/calculate`,
      requestBody: {
        username,
        limit: params.perPage,
        offset: (params.page - 1) * params.perPage,
        search: params.search,
        filter: {
          filters: isKz
            ? omit(
                {
                  ...data.filters,
                  type_game: { game: data.filters.type_game.game },
                },
                [
                  `payment`,
                  `win`,
                  `deposit`,
                  `visits`,
                  `top`,
                  `top_bet`,
                  `guest_count`,
                  `ticket_id`,
                ],
              )
            : data.filters,
          sms: omit(smsData, isKz ? `ticket_id` : ``),
        },
      },
      history,
      ultimateLogout,
    })
  }

  const getNumbers = (params, sec) => {
    clearTimeout(waitNumbers)
    setRequestParams({ ...requestParams, pending: true })
    waitNumbers = setTimeout(() => {
      makeRequest(params)
        .then(({ data: calculateData, headers }) => {
          setRequestParams({
            ...params,
            data: {
              ...calculateData,
              numbers: [...params.data.numbers, ...calculateData.numbers],
            },
            search: params.search,
            pending: false,
            total: headers[`total-count`],
          })
          if (!showNumbers) {
            openModal()
          }
        })
        .catch(error => {
          notification.error({
            message: "Ошибка валидации",
          })
          setRequestParams({ ...requestParams, pending: false })
          if (error && error.response && error.response.data) {
            setValidate(error.response.data)
          }
        })
    }, sec)
  }

  const clearNumbers = () => setRequestParams(initialNumbers)

  return {
    numbersData: requestParams,
    getNumbers,
    makeRequest,
    clearNumbers,
  }
}

const getRange = value => {
  const date = new Date()
  const timeOffset = date.getTimezoneOffset() * 60000
  return value ? value - timeOffset : null
}
export const makeDataLocaleAgain = data => {
  const smsData = data.sms
  const gamesData = data.filters.type_game.game || []
  const utcGames = gamesData.map(item => ({
    ...item,
    dttm_from: getRange(item.dttm_from),
    dttm_to: getRange(item.dttm_to),
  }))

  return {
    sms: smsData,
    filters: {
      ...data.filters,
      confines_period: {
        ...data.filters.confines_period,
        from: getRange(data.filters.confines_period.from),
        to: getRange(data.filters.confines_period.to),
      },
      type_game: {
        ...data.filters.type_game,
        sport: {
          ...data.filters.type_game.sport,
          dttm_from: getRange(data.filters.type_game.sport.dttm_from),
          dttm_to: getRange(data.filters.type_game.sport.dttm_to),
        },
        game: utcGames,
      },
      win: {
        ...data.filters.win,
        dttm_from: getRange(data.filters.win.dttm_from),
        dttm_to: getRange(data.filters.win.dttm_to),
      },
      deposit: {
        ...data.filters.deposit,
        dttm_from: getRange(data.filters.deposit.dttm_from),
        dttm_to: getRange(data.filters.deposit.dttm_to),
      },
    },
  }
}

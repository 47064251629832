import React from "react"
import betRequest from "api/request"
import { notification } from "antd"

const getRequest = (
  requestData,
  setData,
  id,
  history,
  ultimateLogout,
  setRequestValue,
) => {
  setData({
    ...requestData,
    pending: true,
  })
  betRequest({ url: `request/preview/${id}`, history, ultimateLogout })
    .then(({ data }) => {
      setData({
        ...requestData,
        data,
        pending: false,
        status: `completed`,
      })
      setRequestValue(data.request_status)
    })
    .catch(() =>
      setData({
        ...requestData,
        pending: false,
        status: `error`,
      }),
    )
}

export default getRequest

export const getColumn = ({ id, isKz }) => {
  const columnForKz = isKz
    ? []
    : [
        {
          title: "Ответ",
          key: "answer",
          dataIndex: "answer",
          withoutSearch: true,
        },
      ]
  return [
    {
      title: "Номер рассылки",
      key: "id",
      dataIndex: "id",
      withoutSearch: true,
      render: () => <div>{id}</div>,
    },
    {
      title: "Телефон",
      key: "number",
      dataIndex: "number",
    },
    {
      title: "Статус",
      key: "status",
      dataIndex: "status",
      type: "badge",
      withoutSearch: true,
      width: 200,
      sorter: () => null,
    },
    {
      title: "Номер клуба",
      key: "hall",
      dataIndex: "hall",
      withoutSearch: true,
    },
    ...columnForKz,
    {
      title: "Дата добавления",
      key: "add_date",
      dataIndex: "add_date",
      type: `date`,
      withoutSearch: true,
      sorter: () => null,
    },
  ]
}

export const changeStatus = ({
  type,
  requestId,
  statusId,
  setPending,
  history,
  ultimateLogout,
  setItem,
  refresh,
}) => {
  setPending(true)
  const isNumbers = type === `numbers`
  betRequest({
    method: `post`,
    url: `request/${isNumbers ? `change_status_messages` : `change_status`}`,
    requestBody: { id: requestId, status: statusId },
    history,
    ultimateLogout,
  })
    .then(() => {
      setItem(statusId)
      refresh()
      setPending(false)
    })
    .catch(() => {
      setPending(false)
      notification.error({
        message: "Ошибка смены статуса",
      })
    })
}

import React from "react"
import { Select } from "antd"
import SelectComponent from "Components/Select"

const { Option } = Select

const PerPageComponent = ({ perPage, changePerPage, pending }) => {
  return (
    <div>
      <SelectComponent
        defaultValue={perPage}
        onChange={value => changePerPage(value)}
        disabled={pending}
      >
        <Option value={10}>Показать 10 записей</Option>
        <Option value={25}>Показать 25 записей</Option>
        <Option value={50}>Показать 50 записей</Option>
        <Option value={100}>Показать 100 записей</Option>
        <Option value={200}>Показать 200 записей</Option>
      </SelectComponent>
    </div>
  )
}

export default PerPageComponent
